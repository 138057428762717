import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import React from "react";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { FaCircleXmark, FaRegCircleXmark } from "react-icons/fa6";

const _ = require("lodash");
const genders = [
  { value: "G", label: "Male" },
  { value: "L", label: "Female" },
];

const BASE_URL = process.env.REACT_APP_BASE_URL;

const specialCategories = [
  { value: "EWS", label: "EWS" },
  { value: "ORPHAN", label: "ORPHAN" },
  { value: "TFWS", label: "TFWS" },
  { value: "DEF", label: "DEFENCE" },
  { value: "PWD", label: "DISABILITY" },
  { value: "MI", label: "Minority" },
];

export default function BranchWise(props) {
  const universites = props.universites;
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);
  const [selectedSpecialCategory, setSelectedSpecialCategory] = useState(null);
  const [categories, setCategories] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  // const [collegeData, setCollegeData] = useState(null);
  const [isPremium, setIsPremium] = useState(true);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [selectedRound, setSelectedRound] = useState({
    label: "Round 1",
    value: "round1",
  });
  const [isPending, setIsPending] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const [collageInfo, setCollageInfo] = useState([]);
  const [collage, setCollage] = useState(null);
  const [gender, setGender] = useState(null);
  const [isHome, setIsHome] = useState(false);
  const [isState, setIsState] = useState(false);
  const [isOther, setIsOther] = useState(false);
  const removeKeywords = ["PWD", "DEF", "EWS", "ORPHAN", "TFWS"];
  const BASE_URL = process.env.REACT_APP_BASE_URL;
  useEffect(() => {
    setIsPremium(true);
  }, []);

  const submit = async (e) => {
    if (!token) {
      navigate("/login");
      return;
    }

    e.preventDefault();
    setIsPending(true);
    props.setGender(gender != null ? gender.value : "");
    props.setBranch(selectedBranch);
    props.setUniversiity(selectedUniversity);
    props.setCategory(
      selectedSpecialCategory != null ? selectedSpecialCategory : category
    );
    const coll = {
      category: category?.value !== undefined ? category?.value : "",
      branch: selectedBranch?.value !== undefined ? selectedBranch?.value : "",
      college_name: collage?.value !== undefined ? collage?.value : "",
      university:
        selectedUniversity?.label !== undefined
          ? selectedUniversity?.label
          : "",
      round: selectedRound?.value !== undefined ? selectedRound?.value : "",
      gender: gender,
      specialCategary: selectedSpecialCategory?.value,
      universityType: { Home: isHome, State: isState, Other: isOther },
    };
    try {
      const response = await fetch(`${BASE_URL}/result/cutOff`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(coll),
      });

      const data = await response.json();
      if (data) {
        props.setCollegeData(data);
        props.setTableName("Branch-Wise College Cut-off");
        const targetDiv = document.getElementById("form-response");
        if (data.length) {
          props.setIsData(false);
        } else {
          props.setIsData(true);
          props.setMsg("There are no results As per your input");
        }
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: "smooth" });
        }
      } else {
        props.setCollegeData(null);
        props.setTableName("Branch-Wise College Cut-off");
        props?.setIsData(true);
        props.setMsg("There are no results As per your input");
      }
    } catch (err) {
      console.log("Error ", err);
      props?.setIsData(true);
      props.setMsg("There are no results As per your input");
    }
    setIsPending(false);
  };

  const getCollageName = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/colleges`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          university_name: selectedUniversity?.label,
        }),
      });
      const data = await response.json();
      if (data) {
        data.map((collage) => {
          console.log(`"${collage.college_name}"`);
        });
        setCollageInfo(data);
      }
    } catch (err) {
      console.log("Error ", err);
    }
  }, [selectedUniversity]);

  const getCollageDetails = useCallback(async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/collegeDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            college_name: collage?.value,
          }),
        }
      );
      const data = await response.json();
      if (data) {
        const genralCategaryData = processCategories(data[0]?.categories);
        setCategoryData(genralCategaryData);
        setBranchData(data[0]?.branches);
      }
    } catch (err) {
      console.log("Error ", err);
    }
  }, [collage]);

  useEffect(() => {
    setCollage(null);
    getCollageName();
  }, [selectedUniversity, getCollageName]);

  useEffect(() => {
    setSelectedBranch(null);
    setCategory(null);
    setCategoryData([]);
    getCollageDetails();
  }, [collage, getCollageDetails]);

  const filterData = useCallback(
    (selection) => {
      if (selection === "L") {
        return categoryData;
      } else if (selection === "G") {
        return categoryData?.filter((item) => !item.startsWith("L"));
      } else {
        return categoryData;
      }
    },
    [categoryData]
  );

  useEffect(() => {
    const data = filterData(gender?.value);
    setCategories(data);
  }, [gender?.value, filterData]);

  const processCategories = (allCategories) => {
    const filteredCategories = _.filter(allCategories, (category) => {
      return !_.some(removeKeywords, (keyword) =>
        _.includes(category.toUpperCase(), keyword)
      );
    });

    const processedCategories = filteredCategories.map((str) => {
      if (str.length > 2) {
        const core = str.substring(1, str.length - 1);
        return core === "OPE" ? "OPEN" : core;
      }
      return str;
    });

    return _.uniq(processedCategories);
  };

  useEffect(() => {
    if (selectedSpecialCategory != null) {
      setIsHome(false);
      setIsState(false);
      setIsOther(false);
    }
  }, [selectedSpecialCategory]);

  return (
    <>
      <form className="student-data form-class" onSubmit={submit}>
        <h3 className="form-heading-final">Fill the Details</h3>
        <label className="uni-final items-center relative gap-14">
          <span className="w-12">
            Round<span className="compulsory-feilds">*</span>
          </span>
          <Select
            className="spanclass"
            required
            onChange={(option) => setSelectedRound(option)}
            options={[
              { label: "Round 1", value: "round1" },
              { label: "Round 2", value: "round2" },
              { label: "Round 3", value: "round3" },
            ]}
            value={selectedRound}
            placeholder="Select Round"
          />
          {selectedRound !== null ? (
            <button
              button
              className="absolute"
              style={{ right: "-5px" }}
              type="button"
              onClick={() => {
                setSelectedRound(null);
              }}
            >
              <FaRegCircleXmark color="red" />
            </button>
          ) : null}
        </label>

        {isPremium ? (
          <label className="uni-final items-center relative gap-10">
            <span>
              University<span className="compulsory-feilds">*</span>
            </span>
            <Select
              className="spanclass"
              onChange={(option) => {
                setSelectedUniversity(option);
              }}
              options={universites.map((university) => ({
                label: university,
                value: university,
              }))}
              value={selectedUniversity}
              required
              placeholder="Select University"
            />
            {selectedUniversity !== null ? (
              <button
                button
                className="absolute"
                style={{ right: "-5px" }}
                type="button"
                onClick={() => {
                  setSelectedUniversity(null);
                  setCollage(null);
                  setSelectedBranch(null);
                }}
              >
                <FaRegCircleXmark color="red" />
              </button>
            ) : null}
          </label>
        ) : (
          <label className="disabled uni-final">
            <p>University</p>
            <input className="spanclass" type="text" disabled />
          </label>
        )}

        <label className="collage-final items-center gap-14 relative">
          <span>
            College<span className="compulsory-feilds">*</span>
          </span>
          <Select
            className="sam spanclass"
            onChange={(option) => {
              setCollage(option);
            }}
            options={collageInfo.map((collage) => ({
              label: collage.college_name,
              value: collage.college_name,
            }))}
            value={collage}
            isSearchable={true}
            placeholder="Select College"
            required
          />
          {collage !== null ? (
            <button
              button
              className="absolute"
              style={{ right: "-5px" }}
              type="button"
              onClick={() => {
                setCollage(null);
                setSelectedBranch(null);
              }}
            >
              <FaRegCircleXmark color="red" />
            </button>
          ) : null}
        </label>

        {isPremium ? (
          <label className="branch-final align-middle items-center gap-12 relative">
            <span className="w-14">Branch</span>
            <Select
              className="spanclass"
              onChange={(option) => {
                setSelectedBranch(option);
              }}
              options={branchData?.map((branches) => ({
                label: branches,
                value: branches,
              }))}
              value={selectedBranch}
              placeholder="Select Branch"
            />
            {selectedBranch !== null ? (
              <button
                button
                className="absolute"
                style={{ right: "-5px" }}
                type="button"
                onClick={() => {
                  setSelectedBranch(null);
                }}
              >
                <FaRegCircleXmark color="red" />
              </button>
            ) : null}
          </label>
        ) : (
          <label className="disabled branch-final">
            <p>Branch</p>
            <input className="spanclass" type="text" disabled />
          </label>
        )}

        {isPremium ? (
          <label className="category items-center gap-2 relative">
            <span className="w-24">
              Special category<span className="compulsory-fields"></span>
            </span>
            <Select
              className="sam spanclass"
              onChange={(option) => {
                setSelectedSpecialCategory(option);
              }}
              value={selectedSpecialCategory}
              options={specialCategories}
              placeholder="Select Category"
            />
            {selectedSpecialCategory !== null ? (
              <button
                button
                className="absolute"
                style={{ right: "-5px" }}
                type="button"
                onClick={() => {
                  setSelectedSpecialCategory(null);
                }}
              >
                <FaRegCircleXmark color="red" />
              </button>
            ) : null}
          </label>
        ) : (
          <label
            className="category disabled input-disable"
            data-tip="Available with premium features"
          >
            <p>Special category</p>
            <input type="text" className="spanclass" disabled />
          </label>
        )}
        {selectedSpecialCategory === null ? (
          <label className="gender items-center gap-12 relative">
            <span className="w-14">Gender</span>
            <Select
              className="sam spanclass"
              onChange={(option) => {
                setGender(option);
              }}
              options={genders}
              placeholder="Select gender"
              value={gender}
              isSearchable={false}
            />

            {gender !== null ? (
              <button
                button
                className="absolute"
                style={{ right: "-5px" }}
                type="button"
                onClick={() => {
                  setGender(null);
                }}
              >
                <FaRegCircleXmark color="red" />
              </button>
            ) : null}
          </label>
        ) : (
          <label
            className="category disabled input-disable items-center gap-12"
            data-tip="Available with premium features"
          >
            <sapn className="w-14">Gender</sapn>
            <input type="text" className="spanclass" disabled />
          </label>
        )}
        {selectedSpecialCategory === null ||
        selectedSpecialCategory.value === "DEF" ||
        selectedSpecialCategory.value === "PWD" ? (
          <label className="category items-center gap-12 relative">
            <span>
              Category<span className="compulsory-fields"></span>
            </span>
            <Select
              className="sam spanclass"
              onChange={(option) => {
                setCategory(option);
              }}
              value={category}
              options={(categories?.length ? categories : categoryData)?.map(
                (categary) => ({ label: categary, value: categary })
              )}
              placeholder="Select Category"
            />
            {category !== null ? (
              <button
                button
                className="absolute"
                style={{ right: "-5px" }}
                type="button"
                onClick={() => {
                  setCategory(null);
                }}
              >
                <FaRegCircleXmark color="red" />
              </button>
            ) : null}
          </label>
        ) : (
          <label
            className="category disabled input-disable gap-12 items-center"
            data-tip="Available with premium features"
          >
            <span className="w-14">Category</span>
            <input type="text" className="spanclass" disabled />
          </label>
        )}

        <div className="flex justify-between items-center space-x-3 mb-6">
          <span>University type</span>
          <div className="flex justify-between gap-4">
            <div className="flex justify-center items-center gap-3">
              <input
                type="checkbox"
                className="scale-125"
                checked={isHome}
                onChange={() => setIsHome(!isHome)}
                disabled={selectedSpecialCategory != null}
              />
              <strong
                data-tooltip-id="hu-tooltip"
                onClick={() => {
                  selectedSpecialCategory === null && setIsHome(!isHome);
                }}
                className="cursor-pointer"
              >
                Home
              </strong>
              <Tooltip id="hu-tooltip">Home University</Tooltip>
            </div>
            <div className="flex justify-center items-center gap-3">
              <input
                type="checkbox"
                className="scale-125"
                checked={isState}
                onChange={() => setIsState(!isState)}
                disabled={selectedSpecialCategory != null}
              />
              <strong
                data-tooltip-id="su-tooltip"
                onClick={() => {
                  selectedSpecialCategory === null && setIsState(!isState);
                }}
                className="cursor-pointer"
              >
                State
              </strong>
              <Tooltip id="su-tooltip">State University</Tooltip>
            </div>
            <div className="flex justify-center items-center gap-3">
              <input
                type="checkbox"
                className="scale-125"
                checked={isOther}
                onChange={() => setIsOther(!isOther)}
                disabled={selectedSpecialCategory != null}
              />
              <strong
                data-tooltip-id="ohu-tooltip"
                onClick={() => {
                  selectedSpecialCategory === null && setIsOther(!isOther);
                }}
                className="cursor-pointer"
              >
                Other
              </strong>
              <Tooltip id="ohu-tooltip">Other Than Home University</Tooltip>
            </div>
          </div>
        </div>
        {!isPending && (
          <button className="search-button" type="submit">
            Search
          </button>
        )}
        {isPending && (
          <button className="search-button" type="button" disabled>
            Searching..
          </button>
        )}
      </form>
    </>
  );
}
