import { useForm } from "react-hook-form";
import "./NewWorkWithUs.css";
import NewFooter from "../NewFooter/NewFooter";
import axios from "axios";
import { toast } from "react-hot-toast";

const NewWorkWithUs = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = async (data) => {
    console.log(data);

    try {
      const response = await axios.post(
        `${baseUrl}/public/work-with-us`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      toast.success(response.data.message);
      reset();
    } catch (err) {
      console.error("Error submitting form:", err);
      toast.error(
        "An error occurred while submitting the form. Please try again later"
      );
    }
  };

  const watchNetwork = watch("network", "");

  return (
    <div>
      <div className="items-center p-8">
        <div className="flex items-center justify-center flex-col">
          <h1 className="text-2xl text-[#446282] font-semibold mb-4">
            Submit Your Application
          </h1>
          <form className="application-form" onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4">
              <div>
                <label className="block">
                  Full Name *
                  <input
                    {...register("fullName", {
                      required: "Full Name is required",
                    })}
                    type="text"
                    placeholder="Your Full Name"
                    className="input-field"
                  />
                  {errors.fullName && (
                    <span className="error-class">
                      {errors.fullName.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Email Address *
                  <input
                    {...register("emailAddress", {
                      required: "Email is required",
                      pattern: {
                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                        message: "Invalid email address",
                      },
                    })}
                    type="email"
                    placeholder="Your Email Address"
                    className="input-field"
                  />
                  {errors.emailAddress && (
                    <span className="error-class">
                      {errors.emailAddress.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Phone Number *
                  <input
                    {...register("phoneNumber", {
                      required: "Phone number is required",
                      pattern: {
                        value: /^\d{10}$/,
                        message: "Phone number must be exactly 10 digits",
                      },
                    })}
                    type="text"
                    placeholder="Your Phone Number"
                    className="input-field"
                  />
                  {errors.phoneNumber && (
                    <span className="error-class">
                      {errors.phoneNumber.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Location *
                  <input
                    {...register("location", {
                      required: "Location is required",
                    })}
                    type="text"
                    placeholder="Your Location"
                    className="input-field"
                  />
                  {errors.location && (
                    <span className="error-class">
                      {errors.location.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Current Occupation *
                  <input
                    {...register("currentOccupation", {
                      required: "Current Occupation is required",
                    })}
                    type="text"
                    placeholder="Your Current Occupation"
                    className="input-field"
                  />
                  {errors.currentOccupation && (
                    <span className="error-class">
                      {errors.currentOccupation.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Company/Organization (if applicable)
                  <input
                    {...register("companyOrganization")}
                    type="text"
                    placeholder="Your Company/Organization"
                    className="input-field"
                  />
                </label>
              </div>
              <div>
                <label className="block">
                  Experience in Counselling (Years) *
                  <select
                    {...register("experienceCounselling", {
                      required: "Experience in Counselling is required",
                    })}
                    className="input-field"
                  >
                    <option value="">Select</option>
                    <option value="Yes">0-1</option>
                    <option value="No">1-3</option>
                    <option value="No">3-5</option>
                    <option value="No">5+</option>
                  </select>
                  {errors.experienceCounselling && (
                    <span className="error-class">
                      {errors.experienceCounselling.message}
                    </span>
                  )}
                </label>
              </div>
              <div>
                <label className="block">
                  Experience in Sales/Marketing (Years) *
                  <select
                    {...register("experienceSalesMarketing", {
                      required: "Experience in Sales/Marketing is required",
                    })}
                    className="input-field"
                  >
                    <option value="">Select</option>
                    <option value="Yes">0-1</option>
                    <option value="No">1-3</option>
                    <option value="No">3-5</option>
                    <option value="No">5+</option>
                  </select>
                  {errors.experienceSalesMarketing && (
                    <span className="error-class">
                      {errors.experienceSalesMarketing.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                <label className="block">
                  Do you have any existing network or contacts in educational
                  institutions? *
                  <select
                    {...register("existingNetwork", {
                      required: "Network is required",
                    })}
                    className="input-field"
                  >
                    <option value="">Select</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                  {errors.existingNetwork && (
                    <span className="error-class">
                      {errors.existingNetwork.message}
                    </span>
                  )}
                </label>
              </div>
              {watchNetwork === "Yes" && (
                <div className="md:col-span-2">
                  <label className="block">
                    If Yes, please specify
                    <textarea
                      {...register("networkDetails", {
                        required: "Please specify your network details",
                      })}
                      placeholder="Specify your network"
                      className="textarea-field"
                    />
                    {errors.networkDetails && (
                      <span className="error-class">
                        {errors.networkDetails.message}
                      </span>
                    )}
                  </label>
                </div>
              )}
              <div className="md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                <label className="block">
                  Preferred Mode of Communication *
                  <select
                    {...register("preferredCommunication", {
                      required:
                        "Please select your preferred mode of communication",
                    })}
                    className="input-field"
                  >
                    <option value="">Select</option>
                    <option value="Email">Email</option>
                    <option value="Phone">Phone</option>
                    <option value="WhatsApp">WhatsApp</option>
                  </select>
                  {errors.preferredCommunication && (
                    <span className="error-class">
                      {errors.preferredCommunication.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                <label className="block">
                  How can you contribute to our mission of providing excellent
                  educational counselling? *
                  <textarea
                    {...register("contribution", {
                      required: "Contribution is required",
                    })}
                    placeholder="Your contribution"
                    className="textarea-field"
                  />
                  {errors.contribution && (
                    <span className="error-class">
                      {errors.contribution.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="md:col-span-2 lg:col-span-2 xl:col-span-2 2xl:col-span-2">
                <label className="block">
                  Previous Counselling Experience *
                  <textarea
                    {...register("previousExperience", {
                      required: "CounselingDetails required",
                    })}
                    placeholder="Please describe your previous experience in counselling."
                    className="textarea-field"
                  />
                  {errors.previousExperience && (
                    <span className="error-class">
                      {errors.previousExperience.message}
                    </span>
                  )}
                </label>
              </div>
            </div>
            <div>
              <button type="submit" className="btn-submit">
                Submit Your Application
              </button>
            </div>
          </form>
        </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default NewWorkWithUs;
