import Select from "react-select";
import React, {  useState } from 'react';

const genders = [
    { value: "G", label: "Male" },
    { value: "L", label: "Female" },
];

export default function ListGeneratorFinal({ setActiveTab }) {
    const [formData, setFormData] = useState({
        percentile: "",
        gender: ""
    });

    const { percentile, gender } = formData;

    const handleOnSubmit = (e) => {
        e.preventDefault();
        setActiveTab("signup")
    }

    return (
        <div className="full-box-div">
            <div className="final-login-box">
                <h2 className="login-box-head"> MHT-CET Preference <br /> List Generator </h2>
                <form className="final-login-form mt-3" onSubmit={handleOnSubmit}>
                    <input
                        required
                        type='text'
                        name='percentile'
                        value={percentile}
                        onChange={(e) => {
                            setFormData((prevData) => ({
                                ...prevData,
                                [e.target.name]: e.target.value,
                            }))
                        }}
                        placeholder='Enter Percentile'
                    />
                    <label>
                        <Select
                            onChange={
                                (option) => {
                                    setFormData((prevData) => ({
                                        ...prevData,
                                        gender: option.value,
                                    }))
                                }
                            }
                            placeholder="Select Gender"
                            options={genders}
                            defaultValue={gender}
                            required
                        />
                    </label>

                    <div className="button-anchor-container ">
                        <button type="submit" className="btn">Get List</button>
                        <span className="textWhite ml-4">Already have an account? &nbsp; <span style={{ textDecorationLine: "underline", cursor: "pointer" }} onClick={() => { setActiveTab("login") }}>Login</span></span>
                    </div>
                </form>
            </div>
        </div>


    )
}
