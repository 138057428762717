import React, { useEffect, useState } from 'react';
import passshow from '../Assets/img/passshows.png';
import passhide from '../Assets/img/passhide.png';
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { sendOtp, signUp } from '../../services/operations/authAPI';
import { setSignupData } from "../../slice/authSlice";
import { NavLink } from 'react-router-dom';
import OtpInput from "react-otp-input";

export default function FinalSignUp() {
    const { signupData, loading, OtpError } = useSelector((state) => state.auth);
    const [otp, setOtp] = useState("");
    const [signupFormData, setSignupFormData] = useState({
        firstName: "",
        lastName: "",
        newEmail: "",
        contactNumber: "",
        newPassword: "",
        confirmPassword: "",
    });

    const { firstName, lastName, newEmail, newPassword, confirmPassword, contactNumber } = signupFormData;

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState(true);
    const [passwordUrl, setPasswordUrl] = useState(passhide);
    const [verifyEmail, setVerifyEmail] = useState(false);
    const [activeTabHead, setActiveTabHead] = useState('Login to your account');
    const [emailError, setEmailError] = useState('');
    const [contactError, setContactError] = useState('');
    const [passwordMatchError, setPasswordMatchError] = useState('');

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const contactNumberRegex = /^[0-9]{10}$/;

    const handleOnSubmitVerifyEmail = (e) => {
        e.preventDefault();
        if (signupData) {
            const { firstName, lastName, contactNumber, newEmail, newPassword, confirmPassword } = signupData;
            dispatch(signUp(firstName, lastName, newEmail, newPassword, confirmPassword, contactNumber, otp, navigate));
            setActiveTabHead('Login to your account');
        }
    }
    
    const handleOnSubmitSignup = (e) => {
        e.preventDefault();
        if (!emailRegex.test(newEmail)) {
            setEmailError('Invalid Email Id.');
            return;
        } else {
            setEmailError('');
        }

        if (!contactNumberRegex.test(contactNumber)) {
            setContactError('Enter 10-digit number.');
            return;
        } else {
            setContactError('');
        }

        if (newPassword.length < 8) {
            setPasswordMatchError('Min 8 characters');
            return;
        } else {
            setPasswordMatchError('');
        }

        if (newPassword !== confirmPassword) {
            setPasswordMatchError('Password Mismatch');
            return;
        } else {
            setPasswordMatchError('');
        }

        dispatch(setSignupData(signupFormData));
        dispatch(signUp(firstName, lastName, newEmail, newPassword, confirmPassword, contactNumber, navigate))
        // dispatch(sendOtp(newEmail))
        //     .then((response) => {
        //         console.log('OTP sent successfully:', response);
        //         setSignupFormData({
        //             firstName: "",
        //             lastName: "",
        //             newEmail: "",
        //             newPassword: "",
        //             confirmPassword: "",
        //             contactNumber: ""
        //         });
        //         setVerifyEmail(true);
        //         setActiveTabHead('OTP');
        //     })
        // setActiveTabHead('Login to your account')
            .catch((error) => {
                console.error('Error sending OTP:', error);
                setVerifyEmail(false);
            });
    }

    const validateFormData = (updatedFormData) => {
        const { newEmail, contactNumber, newPassword, confirmPassword } = updatedFormData;

        if (!emailRegex.test(newEmail)) {
            setEmailError('Invalid Email Id.');
        } else {
            setEmailError('');
        }

        if (!contactNumberRegex.test(contactNumber)) {
            setContactError('Enter 10-digit number.');
        } else {
            setContactError('');
        }

        if (newPassword.length < 8) {
            setPasswordMatchError('Min 8 characters');
        } else if (newPassword !== confirmPassword) {
            setPasswordMatchError('Password Mismatch');
        } else {
            setPasswordMatchError('');
        }
    }

    const handleOnChangeSignUp = (e) => {
        const { name, value } = e.target;

        setSignupFormData((prevData) => {
            const updatedFormData = { ...prevData, [name]: value };
            validateFormData(updatedFormData);
            return updatedFormData;
        });
    }

    const togglePasswordVisibility = () => {
        setPasswordType(!passwordType);
        setPasswordUrl(passwordType ? passshow : passhide);
    }

    useEffect(() => {
        if (OtpError) {
            setVerifyEmail(false);
        } else {
            // setVerifyEmail(true);
        }
    }, [OtpError]);

    return (
        <div className="">
            <div className="final-login-box">
                {
                    // verifyEmail ?
                    //     <div className='max-w-[500px] flex items-center justify-center'>
                    //         {
                    //             loading ? (
                    //                 <div className='w-full h-400px flex items-center justify-center'>
                    //                     Loading....
                    //                 </div>
                    //             ) : (
                    //                 <div className='verify-email-box-new ml-0 flex flex-col gap-3  rounded-md'>
                    //                     <p className='font-normal text-base mb-2 text-color-white'>Enter OTP sent to {signupData?.newEmail}</p>
                    //                     <form onSubmit={handleOnSubmitVerifyEmail} className='form outline-none border-none shadow-none otpinput'>
                    //                         <div className='flex justify-center mx-auto border-none ml-0'>
                    //                             <OtpInput
                    //                                 value={otp}
                    //                                 onChange={setOtp}
                    //                                 numInputs={6}
                    //                                 renderInput={(props) => <input {...props} />}
                    //                                 separator={<span style={{ width: "9px", margin: "2px" }}>-</span>}
                    //                                 inputStyle={{
                    //                                     border: "1px solid #161D29",
                    //                                     borderRadius: "8px",
                    //                                     width: "50px",
                    //                                     height: "50px",
                    //                                     fontSize: "16px",
                    //                                     fontWeight: "500",
                    //                                     caretColor: "blue",
                    //                                     margin: "0px 4px",
                    //                                 }}
                    //                                 focusStyle={{
                    //                                     outline: "none",
                    //                                 }}
                    //                                 isInputNum={true}
                    //                                 shouldAutoFocus={true}
                    //                                 focus={true}
                    //                             />
                    //                         </div>
                    //                         <div className="button-anchor-container">
                    //                             <button type="submit" className="btn ml-0">Verify</button>
                    //                             <NavLink>
                    //                                 <span onClick={() => dispatch(sendOtp(signupData?.newEmail, navigate))} className='forgot-password'>
                    //                                     <p className='mb-1'>Resend it</p>
                    //                                 </span>
                    //                             </NavLink>
                    //                         </div>
                    //                     </form>
                    //                 </div>
                    //             )
                    //         }
                    //     </div>
                    //     :
                    loading ? (
                        <div className='w-full h-400px flex items-center justify-center'>
                            Loading....
                        </div>
                    ) :
                        <form className="signup-form" onSubmit={handleOnSubmitSignup}>
                            <div className='form-name'>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='firstName'
                                        value={firstName}
                                        onChange={handleOnChangeSignUp}
                                        placeholder='Your First Name'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                        }}
                                    />
                                </label>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='lastName'
                                        value={lastName}
                                        onChange={handleOnChangeSignUp}
                                        placeholder='Your Last Name'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                        }}
                                    />
                                </label>
                            </div>
                            <div className='form-name'>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='newEmail'
                                        value={newEmail}
                                        onChange={handleOnChangeSignUp}
                                        placeholder='Your Email'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                        }}
                                    />
                                    {emailError && <p className="error-class" style={{ color: 'red' }}>{emailError}</p>}
                                </label>
                                <label>
                                    <input
                                        required
                                        type='number'
                                        name='contactNumber'
                                        value={contactNumber}
                                        onChange={handleOnChangeSignUp}
                                        placeholder='+00 0000000'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                        }}
                                    />
                                    {contactError && <p className="error-class" style={{ color: 'red' }}>{contactError}</p>}
                                </label>
                            </div >
                            <div className='form-name'>
                                <div className='relative'>
                                    <label>
                                        <input
                                            required
                                            type={passwordType ? 'password' : 'text'}
                                            name='newPassword'
                                            value={newPassword}
                                            onChange={handleOnChangeSignUp}
                                            placeholder='Password'
                                            style={{
                                                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                            }}
                                        />
                                    </label>
                                    <img
                                        className='fa-eye-slash passclass absolute right-2 top-4'
                                        src={passwordUrl}
                                        alt="Toggle Password Visibility"
                                        style={{ cursor: "pointer" }}
                                        onClick={togglePasswordVisibility}
                                    /> 
                                </div>
                                <div className='relative'>
                                    <label>
                                        <input
                                            required
                                            type={passwordType ? 'password' : 'text'}
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={handleOnChangeSignUp}
                                            placeholder='Confirm Password'
                                            style={{
                                                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                            }}
                                        />
                                        {passwordMatchError && <p className="error-class" style={{ color: 'red' }}>{passwordMatchError}</p>}
                                    </label>
                                    <img
                                        className='fa-eye-slash passclass absolute right-2 top-4'
                                        src={passwordUrl}
                                        alt="Toggle Password Visibility"
                                        style={{ cursor: "pointer" }}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </div>

                            <div className="button-anchor-container-for-next">
                                <button type="submit" className="btn">Next</button>
                            </div>
                        </form>
                }
            </div>
        </div>
    )
}
