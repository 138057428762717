
import React, { useState } from 'react';
import passshow from '../Assets/img/passshows.png';
import passhide from '../Assets/img/passhide.png';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux"
import { login } from '../../services/operations/authAPI';

export default function FinalLogin(props) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordType, setpasswordType] = useState(true);
    const [passwordurl, setpasswordurl] = useState(passhide);
    const [formData, setFormData] = useState({ email: "", password: "" });
    const { email, password } = formData;

    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleOnSubmit = (e) => {
        e.preventDefault();
        dispatch(login(email, password, navigate("/")));
    }

    const togglePasswordVisibility = () => {
        setpasswordType(!passwordType)
        if (passwordurl === passshow) {
            setpasswordurl(passhide)
        } else {
            setpasswordurl(passshow)
        }
    }

    return (
        <div className="">
            <div className="final-login-box">
                <form className="final-login-form" onSubmit={handleOnSubmit}>
                    <input
                        required
                        type='text'
                        name='email'
                        value={email}
                        onChange={handleOnChange}
                        placeholder='Enter Email address'
                    />
                    <div className="password-toggle">
                        <input
                            required
                            type={passwordType ? 'password' : 'text'}
                            value={password}
                            name='password'
                            onChange={handleOnChange}
                            placeholder='Enter Password'
                        >
                        </input>
                        <span className="toggle-password" >
                            <img className="fa-eye-slash passclass" src={passwordurl} alt='password' onClick={() => togglePasswordVisibility()} />
                        </span>
                    </div>
                    <div className="button-anchor-container">
                        <button type="submit" className="btn">Login</button>
                        <span className="forgot-password" onClick={() => { props.setActiveTab("forgot") }}>Forgot password</span>
                    </div>
                </form>
            </div>
        </div>
    )
}
