import "./FinalCommonBanner.css"
import React, { useEffect } from 'react';
import contact from "../Assets/img/ContactUs.png"
import aboutbg from "../Assets/img/AboutUs.png"
import offerbg from "../Assets/img/Whatweoffer.png"


export default function FinalCommonBanner({text ,uniqueKey}) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>   
    <div className="final-banner">
    <div className="image-container">
        <img className="image-class-for-banner" src={uniqueKey === 'about' ? aboutbg : uniqueKey === 'contact' ? contact : offerbg } alt="Banner"></img>
    </div>
    <h2 className="banner-text">{text}</h2>
    </div>
    </>  
  );
}
