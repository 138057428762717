import React, { useEffect, useState } from 'react'
// import { Route, Routes } from 'react-router-dom'
// import ListGeneratorFinal from '../ListGeneratorFinal/ListGeneratorFinal'
import FinalLogin from '../FinalLogin/FinalLogin'
import FinalSignUp from '../FinalSignUp/FinalSignUp'
import ListGeneratorFinal from '../ListGeneratorFinal/ListGeneratorFinal';
// import img from "../Assets/img/GDP.jpeg";
import ForgotPassword from '../ForgotPassword/ForgotPassword';
import { useLocation } from 'react-router-dom';
function Welcome() {

  const location = useLocation();
  const { active } = location.state || {};
  const [activeTabHead, setActiveTabHead] = useState('Sign Up for an account');
  const [activeTab, setActiveTab] = useState(active !== undefined ? active : 'getList');

  useEffect(() => {
    if (activeTab === "login") {
      setActiveTabHead("Login to your account")
    } else if (activeTab === "forgot") {
      setActiveTabHead("Forgot Password")
    }
  }, [activeTab])

  return (
    <>
      <div className="container-fluid full-height">
        <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
          <div className="col-span-7 sm:col-span-6 xs:col-span-6 xs:p-2 left-welcome-forms xs:order-2 sm:order-2 flex items-center justify-center flex-col xs:py-16">
            {activeTab === "getList" ?
              <ListGeneratorFinal setActiveTab={setActiveTab} />
              : null
            }
            <div className="full-box-div">
              {
                activeTab !== 'getList' ?
                  <h2 className="login-box-head">
                    {activeTabHead}
                  </h2> : null
              }
              {activeTab !== 'getList' && activeTab !== 'forgot' ?
                <div className={`login-links ${activeTabHead === 'OTP' ? 'd-none' : ''}`}>
                  <span className={`login-anchor-links ${activeTab === 'signup' ? 'login-active' : 'login-inactive'} cursor-pointer`} onClick={() => { setActiveTab('signup'); setActiveTabHead('Sign Up for an account'); }}> Sign Up</span>
                  <span className={`login-anchor-links ${activeTab === 'login' ? 'login-active' : 'login-inactive'} cursor-pointer`} onClick={() => { setActiveTab('login'); setActiveTabHead('Login to your account'); }} >Log In        </span>
                </div> : null
              }
              <div>
                {
                  activeTab === 'signup' ? <FinalSignUp /> : activeTab === 'login' ? <FinalLogin setActiveTab={setActiveTab} /> : activeTab === "forgot" ? <ForgotPassword setActiveTab={setActiveTab} /> : null
                }
              </div>
            </div>
          </div>
          <div className="col-span-5 sm:col-span-6 xs:col-span-6 right-login-div p-2 xs:order-1 sm:order-1 text-center">
            <div className=" text-center ">
              <h2 className="welcome-class">Welcome To</h2>
              <h2 className="campus-class">Campus Connect</h2>
              <p className="para-class">Navigating the journey from high school to college can be
                overwhelming. At Campus Connect, we are dedicated to
                simplifying the admission process for students and parents in
                Maharashtra. Our platform offers personalized guidance, expert
                support, and comprehensive resources to help you secure
                admission to your dream college.</p>
            </div>
          </div>
        </div>
      </div >
    </>
  )
}

export default Welcome