import { toast } from "react-hot-toast"
import { setLoading, setToken, setOtpError, setUser } from "../../slice/authSlice";
import { apiConnector } from "../apiconnector"
import { endpoints } from "../apis"

const { SENDOTP_API, SIGNUP_API, LOGIN_API, FORGOT_PASSWORD, RESET_PASSWORD } = endpoints;

export function sendOtp(email) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...")
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", SENDOTP_API, {
        email,
        checkUserPresent: true,
      })

      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      dispatch(setOtpError(false))
      toast.success("OTP Sent Successfully")
      // navigate("/verify-email")
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      } else {
        toast.error("Could Not Send OTP")
      }
      dispatch(setOtpError(true))
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
  }
}

export function signUp(
  firstName,
  lastName,
  newEmail,
  newPassword,
  confirmPassword,
  contactNumber,
  // otp,
  navigate
) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...")
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", SIGNUP_API, {
        firstName,
        lastName,
        email: newEmail,
        password: newPassword,
        confirmPassword,
        contactNumber,
        // otp,
      })
      console.log(response);
      // dispatch(setOtpVerifyError(false))
      // if (!response.data.success) {
      //   throw new Error(response.data.message)
      // }
      toast.success("Signup Successful")
      window.location.reload()
      navigate("/")
    } catch (error) {
      console.log(error);
      // if (error?.response?.data?.message) {
      //   if (error?.response?.data?.message === "OTP is not valid") {
      //     dispatch(setOtpVerifyError(true))
      //   } else {
      //     navigate("/")
      //     dispatch(setOtpVerifyError(false))
      //   }
      //   toast.error(error?.response?.data?.message)
      // } else {
      //   dispatch(setOtpVerifyError(false))
      //   toast.error("Signup Failed Try After Sometime")
      //   navigate("/")
      // }
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
  }
}

export function login(email, password) {
  console.log(email, password)
  return async (dispatch) => {
    const toastId = toast.loading("Loading...")
    dispatch(setLoading(true))
    try {
      const response = await apiConnector("POST", LOGIN_API, { email, password })
      if (!response.data.success) {
        throw new Error(response.data.message)
      }
      toast.success("Login Successful")
      dispatch(setToken(response.data.token))
      dispatch(setUser(response.data.user));
      localStorage.setItem("token", JSON.stringify(response.data.token))
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("expireTime", JSON.stringify(response.data.expiresIn));
    } catch (error) {
      console.log("LOGIN API ERROR............", error)
      toast.error("Entered Email id or password is wrong")
    }
    dispatch(setLoading(false))
    toast.dismiss(toastId)
  }
}

export function forgotPassword(email) {
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", FORGOT_PASSWORD, { email });
      console.log("forgotPassword RESPONSE............", response);
      if (!response.data.success) {
        throw new Error(response?.data?.message);
      }
      toast.success("Password resend link is sent successfully");
      dispatch(setLoading(false));
      toast.dismiss(toastId);
      return response.data;  // Return the response data
    } catch (error) {
      console.log("forgotPassword ERROR............", error);
      toast.error("Entered Email id is not registered ");
      dispatch(setLoading(false));
      toast.dismiss(toastId);
      throw error;  // Throw error to be caught in the component
    }
  };
}

export function resetPassword(formData, navigate) {
  const { id, newPassword, confirmPassword, token } = formData;
  console.log(id, newPassword, confirmPassword, token);
  return async (dispatch) => {
    const toastId = toast.loading("Loading...");
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", RESET_PASSWORD, {
        id,
        newPassword,
        confirmPassword,
        token
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log("forgotPassword RESPONSE............", response);
      if (!response.data.success) {
        throw new Error(response?.data?.message);
      }
      toast.success("Password reset successfully");
      navigate("/", { state: { active: "login" } });
      dispatch(setLoading(false));
      toast.dismiss(toastId);
      return response.data;  // Return the response data
    } catch (error) {
      console.log("forgotPassword ERROR............", error.response.data.message);
      toast.error(error.response.data.message);
      dispatch(setLoading(false));
      toast.dismiss(toastId);
      throw error;  // Throw error to be caught in the component
    }
  };
}

export function logout(navigate) {

  return (dispatch) => {
    console.log("Logout Function");
    dispatch(setToken(null))
    localStorage.removeItem("token")
    toast.success("Logged Out")
    navigate("/")
  }
}

export function contactUs(contactData, navigate) {
  return async (dispatch) => {
    console.log("contactUs Function");
    try {
      // const response = await apiConnector("POST", CONTACT_US, {
      //   contactData
      // })

      // if (!response.data.success) {
      //   throw new Error(response.data.message)
      // }
      toast.success("Thank You for filling the form")
      // navigate("/verify-email")
    } catch (error) {
      if (error?.response?.data?.message) {
        toast.error(error?.response?.data?.message)
      } else {
        toast.error("Something went wrong")
      }
    }
    // navigate("/")
  }
}