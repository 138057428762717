import { useState, useEffect, useCallback } from "react";
import Select from "react-select";
import React from "react";
import { useNavigate } from "react-router-dom";
import 'jspdf-autotable';
import { useSelector } from "react-redux";
import { FaRegCircleXmark } from "react-icons/fa6";

const _ = require('lodash');

export default function BranchTopCollage(props) {
    const navigate = useNavigate();
    const [branchData, setBranchData] = useState([]);
    const [branches, setBranches] = useState([]);
    const [subBranch, setSubBranch] = useState([]);
    const [isPremium, setIsPremium] = useState(true);
    const [selectedBranch, setSelectedBranch] = useState(null);
    const [selectedSubBranch, setSelectedSubBranch] = useState(null);
    const [isPending, setIsPending] = useState(false);
    const { token } = useSelector((state) => state.auth);
    const BASE_URL = process.env.REACT_APP_BASE_URL;
    useEffect(() => {
        setIsPremium(true);
    }, []);

    const submit = async (e) => {
        if (!token) {
            navigate('/login');
            return;
        }

        e.preventDefault();
        setIsPending(true);
        props.setBranch(selectedBranch)
        props.setRelatedBranch(selectedSubBranch)
        const coll = {
            branch: selectedSubBranch?.value !== undefined ? selectedSubBranch?.value : "",
        };

        try {
            const response = await fetch(`${BASE_URL}/result/branchTopCollage`, {
                // const response = await fetch("http://localhost:4000/api/result/branchTopCollage", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify(coll),
            });

            const data = await response.json();
            if (data) {
                props.setCollegeData(data);
                props.setTableName("Branch-Wise Top College");
                const targetDiv = document.getElementById('form-response');
                if (data.length) {
                    props.setIsData(false);
                } else {
                    props.setIsData(true);
                    props.setMsg("There are no results As per your input");
                }
                if (targetDiv) {
                    targetDiv.scrollIntoView({ behavior: 'smooth' });
                }
            } else {
                props.setCollegeData(null);
                props.setTableName("Branch-Wise Top College ");
                props?.setIsData(true);
                props.setMsg("There are no results As per your input")
            }
        } catch (err) {
            console.log("Error ", err);
            props?.setIsData(true);
            props.setMsg("There are no results As per your input")
        }
        setIsPending(false);
    };

    const getBranches = async () => {
        try {
            const response = await fetch(`${BASE_URL}/public/branches`, {

                // const response = await fetch("http://localhost:4000/api/public/branches", {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            });
            const data = await response.json();
            if (data) {
                delete data["Undefined Group"];
                setBranchData(Object.keys(data));
                setBranches(data);
            } else {

            }
        } catch (err) {
            console.log("Error ", err);
        }
    }

    useEffect(() => {
        setSubBranch(branches[selectedBranch?.label])
    }, [selectedBranch?.label, selectedBranch?.value])

    useEffect(() => {
        getBranches();
    }, []);

    return (
        <>
            <form className="student-data form-class" onSubmit={submit}>
                <h3 className="form-heading-final">Fill the Details</h3>
                {/* <label className="percentile align-middle items-center gap-14">
                    <p className="text-sm">
                        Name<span className="compulsory-feilds">*</span>
                    </p>
                    <input
                        type="text"
                        required
                        onChange={(e) => { props.setForName(e.target.value) }}
                        value={props.forName}
                        placeholder="Enter Name"
                    />
                </label> */}
                {isPremium ? (
                    <label className="branch-final align-middle items-center gap-12 relative">
                        <span className="w-14">Branch<span className="compulsory-feilds">*</span></span>
                        <Select
                            className="spanclass"
                            onChange={(option) => {
                                setSubBranch([])
                                setSelectedSubBranch(null)
                                setSelectedBranch(option);
                            }}
                            options={branchData?.map(branches => ({
                                label: branches,
                                value: branches
                            }))}
                            value={selectedBranch}
                            placeholder="Select Branch"
                            required
                        />
                        {selectedBranch !== null ?
                            <button button className="absolute" style={{ right: "-5px" }}
                                type="button"
                                onClick={() => {
                                    setSelectedBranch(null)
                                    setSelectedSubBranch(null)
                                }}
                            >
                                <FaRegCircleXmark color="red" />
                            </button> : null
                        }

                    </label>
                ) : (
                    <label className="disabled branch-final">
                        <p>Branch</p>
                        <input className="spanclass" type="text" disabled />
                    </label>
                )}

                {isPremium ? (
                    <label className="branch-final align-middle items-center gap-12 relative">
                        <span className="w-14">Related Branch<span className="compulsory-feilds">*</span></span>
                        <Select
                            className="spanclass"
                            onChange={(option) => {
                                setSelectedSubBranch(option);
                            }}
                            options={subBranch?.map(branch => ({
                                label: branch,
                                value: branch
                            }))}
                            value={selectedSubBranch}
                            placeholder="Select Branch"
                            required
                        />
                        {selectedSubBranch !== null ?
                            <button button className="absolute" style={{ right: "-5px" }}
                                type="button"
                                onClick={() => {
                                    setSelectedSubBranch(null)
                                }}
                            >
                                <FaRegCircleXmark color="red" />
                            </button> : null
                        }
                    </label>
                ) : (
                    <label className="disabled branch-final">
                        <p>Related Branch</p>
                        <input className="spanclass" type="text" disabled />
                    </label>
                )}

                {!isPending && <button className="search-button" type="submit">Search</button>}
                {isPending && <button className="search-button" type="button" disabled>Searching..</button>}
            </form >
        </>
    );
}