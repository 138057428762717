import React, { useState } from 'react';
import passshow from '../Assets/img/passshows.png';
import passhide from '../Assets/img/passhide.png';
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from 'react-router-dom';
import { resetPassword } from '../../services/operations/authAPI';
function ResetPassword() {

    const { id, token } = useParams();
    const [resetPasswordFormData, setResetPasswordFormData] = useState({
        id: id,
        newPassword: "",
        confirmPassword: "",
        token: token
    });
    const { newPassword, confirmPassword } = resetPasswordFormData;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [passwordType, setPasswordType] = useState(true);
    const [passwordUrl, setPasswordUrl] = useState(passhide);
    const [passwordMatchError, setPasswordMatchError] = useState('');

    const handleOnSubmitSignup = (e) => {
        e.preventDefault();
        if (newPassword.length < 8) {
            setPasswordMatchError('Min 8 characters');
            return;
        } else {
            setPasswordMatchError('');
        }

        if (newPassword !== confirmPassword) {
            setPasswordMatchError('Password Mismatch');
            return;
        } else {
            setPasswordMatchError('');
        }

        dispatch(resetPassword(resetPasswordFormData, navigate)).then((response) => {
            console.log('OTP sent successfully:', response);
            setResetPasswordFormData({
                newPassword: "",
                confirmPassword: "",
            });
            // setVerifyEmail(true);
            // setActiveTabHead('OTP');
        }).catch((error) => {
            console.error('Error sending OTP:', error);
            // setVerifyEmail(false);
        });
    }

    const handleOnChangeSignUp = (e) => {
        setResetPasswordFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }));
    }

    const togglePasswordVisibility = () => {
        setPasswordType(!passwordType);
        setPasswordUrl(passwordType ? passshow : passhide);
    }

    return (
        <div className="">
            <div className="container-fluid full-height">
                <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12">
                    <div className="col-span-7 sm:col-span-6 xs:col-span-6 xs:p-2 left-welcome-forms xs:order-2 sm:order-2 flex items-center justify-center flex-col xs:py-16">
                        <form className="signup-form" onSubmit={handleOnSubmitSignup}>
                            <div className='form-name'>
                                <div className='relative'>
                                    <label>
                                        <input
                                            required
                                            type={passwordType ? 'password' : 'text'}
                                            name='newPassword'
                                            value={newPassword}
                                            onChange={handleOnChangeSignUp}
                                            placeholder='Password'
                                            style={{
                                                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                            }}
                                        />
                                        {passwordMatchError && <p className="error-class" style={{ color: 'red' }}>{passwordMatchError}</p>}
                                    </label>
                                    <img
                                        className='fa-eye-slash passclass absolute right-2 top-4'
                                        src={passwordUrl}
                                        alt="Toggle Password Visibility"
                                        style={{ cursor: "pointer" }}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                                <div className='relative'>
                                    <label>
                                        <input
                                            required
                                            type={passwordType ? 'password' : 'text'}
                                            name='confirmPassword'
                                            value={confirmPassword}
                                            onChange={handleOnChangeSignUp}
                                            placeholder='Confirm Password'
                                            style={{
                                                boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)",
                                            }}
                                        />
                                    </label>
                                    <img
                                        className='fa-eye-slash passclass absolute right-2 top-4'
                                        src={passwordUrl}
                                        alt="Toggle Password Visibility"
                                        style={{ cursor: "pointer" }}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </div>
                            <div className="button-anchor-container-for-next">
                                <button type="submit" className="btn">Reset</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-span-5 sm:col-span-6 xs:col-span-6 right-login-div p-2 xs:order-1 sm:order-1 text-center">
                        <div className=" text-center ">
                            <h2 className="welcome-class">Welcome To</h2>
                            <h2 className="campus-class">Campus Connect</h2>
                            <p className="para-class">Navigating the journey from high school to college can be
                                overwhelming. At Campus Connect, we are dedicated to
                                simplifying the admission process for students and parents in
                                Maharashtra. Our platform offers personalized guidance, expert
                                support, and comprehensive resources to help you secure
                                admission to your dream college.</p>
                        </div>
                    </div>
                </div>
            </div >
        </div>
    )
}

export default ResetPassword