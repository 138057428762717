import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import crypto from 'crypto-js';
import axios from 'axios';
import logo from '../Assets/img/logoBg.png';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { setUser } from '../../slice/authSlice';

const loadScript = (src) => new Promise((resolve) => {
  const script = document.createElement('script');
  script.src = src;
  script.onload = () => resolve(true);
  script.onerror = () => {
    console.error('Failed to load Razorpay script');
    toast.error('Failed to load Razorpay script');
    resolve(false);
  };
  document.body.appendChild(script);
});

const RenderRazorpay = ({ orderId, keyId, keySecret, currency, amount, userId, onClose, triggerDownload, displayRazorpay, togglePopup }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const checkOutUrl = process.env.REACT_APP_RAZORPAY_CHECKOUT_URL;
  const rzpRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!displayRazorpay) return; // Exit if Razorpay should not be displayed
    const initializeRazorpay = async () => {
      console.log('Initializing Razorpay');
      const scriptLoaded = await loadScript(checkOutUrl);
      if (!scriptLoaded) {
        console.log('Razorpay SDK failed to load. Are you online?');
        return;
      }

      const options = {
        key: keyId,
        amount: amount * 100,
        currency,
        name: 'Campus Connect',
        image: logo,
        order_id: orderId,
        handler: async (response) => {
          console.log('Payment succeeded:', response);
          const succeeded = crypto.HmacSHA256(`${orderId}|${response.razorpay_payment_id}`, keySecret).toString() === response.razorpay_signature;
          await handlePayment(succeeded ? 'succeeded' : 'failed', {
            orderId,
            paymentId: response.razorpay_payment_id,
            signature: response.razorpay_signature,
            userId,
          });
          if (rzpRef.current) {
            rzpRef.current.close();
            rzpRef.current = null;
          }
          onClose();
        },
        modal: {
          confirm_close: true,
          ondismiss: (reason) => {
            console.log('Payment cancelled or failed:', reason);
            if (reason === undefined) {
              handlePayment('Cancelled', { orderId, userId });
              toast.error('Payment Cancelled!');
            } else if (reason === 'timeout') {
              handlePayment('timedout', { orderId, userId });
              toast.error('Payment timed out!');
            } else {
              handlePayment('failed', { orderId, userId });
              toast.error('Payment transaction failed');
            }
            if (rzpRef.current) {
              rzpRef.current.close();
              rzpRef.current = null;
            }
            onClose();
          },
        },
        retry: {
          enabled: false,
        },
        timeout: 900,
      };
      if (!rzpRef.current) {
        rzpRef.current = new window.Razorpay(options);
      }
      rzpRef.current.open();
    };
    initializeRazorpay();
    return () => {
      if (rzpRef.current) {
        rzpRef.current.close();
        rzpRef.current = null;
      }
      onClose();
    };

  }, [orderId, keyId, keySecret, currency, amount, userId, displayRazorpay, checkOutUrl, onClose]);


  const handlePayment = async (status, details) => {
    try {
      console.log('Sending payment status update:', status, details);
      const postData = {
        razorpay_order_id: details.orderId,
        razorpay_payment_id: details.paymentId,
        user_id: details.userId,
        razorpay_signature: details.signature,
      };
      const response = await axios.put(
        `${baseUrl}/payment/update-status`,
        postData
      );
      console.log('Payment status updated:', response.data);
      localStorage.setItem('user', JSON.stringify(response.data.user));
      dispatch(setUser(response.data));
      toast.success('Payment Successful');
      triggerDownload();
    } catch (error) {
      console.error('Error updating payment status:', error);
      toast.error('Failed to update payment status');
      togglePopup()
    } finally {
      onClose();
    }
  };

  return null; // No UI to render
};

RenderRazorpay.propTypes = {
  orderId: PropTypes.string.isRequired,
  keyId: PropTypes.string.isRequired,
  keySecret: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  userId: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RenderRazorpay;
