
import React, { useState } from 'react';
import { useDispatch } from "react-redux"
import { forgotPassword } from '../../services/operations/authAPI';

export default function ForgotPassword(props) {
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({ email: "", });

    const { email } = formData;

    const handleOnChange = (e) => {
        setFormData((prevData) => ({
            ...prevData,
            [e.target.name]: e.target.value,
        }))
    }

    const handleOnSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await dispatch(forgotPassword(email));
            console.log(res);
            if (res.success) {

            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className='mt-3'>
            <div className="final-login-box">
                <form className="final-login-form" onSubmit={handleOnSubmit}>
                    <input
                        required
                        type='text'
                        name='email'
                        value={email}
                        onChange={handleOnChange}
                        placeholder='Enter Email address'
                    />
                    <div className="button-anchor-container">
                        <button type="submit" className="btn">Send Email</button>
                        <span className="forgot-password" onClick={() => { props.setActiveTab("login") }}>Back to Login</span>
                    </div>
                </form>
            </div>
        </div>
    )
}
