import React from 'react'

const Error = () => {
  return (
    <div className='h-[90vh] flex items-center justify-center'>
        <div className=' w-full flex items-center justify-center'>
            <h1 className='text-4xl text-richblack-4 font-poppins font-medium'>Error - 404 Not Found</h1>
        </div>
    </div>
  )
}
export default Error;