import "./App.css";
import { Route, Routes } from "react-router-dom";
import NewNavbar from "./components/NewNavbar/NewNavBar";
// import MobileMenuBar from "./components/MobileMenuBar/MobileMenu";
import NewContactUs from "./components/NewContact/NewContact";
import FinalForm from "./components//FinalForm/FinalForm";
// import MobileFinalLogin from "./components/MobileFinalLogin/MobileLogin";
import NewAbout from "./components/NewAboutUs/NewAboutus";
import Offer from "./components/Offer/Offer";
import React, { useEffect, useState } from 'react';
import Error from "./components/Common/Error";
import { useSelector } from 'react-redux'
import VerifyEmail from "./pages/VerifyEmail";
import FinalCommonBanner from "./components/FinalCommonBanner/FinalCommonBanner";
import NewFooter from "./components/NewFooter/NewFooter";
import Welcome from "./components/Welcomepage/Welcome";
import Policy from "./components/Policy/Policy";
import ResetPassword from "./components/ResetPassword/ResetPassword";
import NewWorkWithUs from "./components/NewWorkWithUs/NewWorkWithUs";
import TalkWithExpert from "./components/TalkWithExpert/TalkWithExpert";
import RefundPolicy from "./components/RefundPolicy/RefundPolicy";
import TermsAndConditions from "./components/Policy/TeermsAndConditions/TermsAndConditions";
import Gallery from "./components/NewGallery/NewGallery";


function App() {

  const { token } = useSelector((state) => state.auth);
  const [isExpired, setIsExpired] = useState(false);
  useEffect(() => {
    const checkExpiration = () => {
      const expireTime = JSON.parse(localStorage.getItem("expireTime"));
      if (expireTime) {
        if (hasExpired(expireTime)) {
          setIsExpired(true);
          console.log(expireTime, isExpired);
          clearLocalStorage(); // No need to check `expireTime` here
          window.location.reload();
        } else {
          setIsExpired(false);
        }
      } else {
        clearLocalStorage();
      }
    };

    // Check expiration immediately on mount
    checkExpiration();

    // Set interval to check expiration every 5 minutes (300,000 milliseconds)
    const intervalId = setInterval(checkExpiration, 300000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <React.StrictMode>
      <div className="App noselect">
        <NewNavbar></NewNavbar>
        {/* <MobileMenuBar />  */}
        <Routes>
          <Route path="/" element={!token ? <><Welcome /> </> : <><FinalForm /> </>}></Route>
          <Route path="/about" element={<><FinalCommonBanner text="About Us" uniqueKey="about" /><NewAbout /></>}></Route>
          <Route path="/what-we-offer" element={<><FinalCommonBanner text="What We Offer" uniqueKey="offer" /><Offer /><NewFooter /></>}></Route>
          <Route path="/contact-us" element={<><FinalCommonBanner text="Contact Us" uniqueKey="contact" /><NewContactUs /> <div className="footer-for-contact"></div></>}></Route>
          <Route path="/verify-email" element={<VerifyEmail />} />
          <Route path="/privacy-policy" element={< Policy />} />
          <Route path="/reset-password/:id/:token" element={<ResetPassword />} />
          <Route path="/work-with-us" element={<><FinalCommonBanner text="Work with Us" uniqueKey="work with us" /><NewWorkWithUs /> <div className="footer-for-contact"></div></>}></Route>
          <Route path="/talk-with-expert" element={<><FinalCommonBanner text="Talk with expert" uniqueKey="talk with expert" /><TalkWithExpert /></>} />
          <Route path="/refund-policy" element={<RefundPolicy />} />
          <Route path="*" element={<Error />} />
          <Route path="/terms-and-conditons" element={<TermsAndConditions />} />
          <Route path="/gallery" element={<><FinalCommonBanner text="Videos Gallery" uniqueKey="Gallery" /><Gallery /> <div className="footer-for-contact"></div></>} />
        </Routes>
      </div>
    </React.StrictMode>
  );
}
const hasExpired = (expireTime) => {
  const currentTime = new Date();
  const expirationTime = new Date(expireTime);
  return currentTime >= expirationTime;
};

export const clearLocalStorage = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
  localStorage.removeItem('expireTime');
};
export default App;