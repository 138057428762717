import { useState, useEffect } from "react";
import Select from "react-select";
import React from "react";
import { useNavigate } from "react-router-dom";
import "jspdf-autotable";
import { useSelector } from "react-redux";
import { FaRegCircleXmark } from "react-icons/fa6";
import { Tooltip } from "react-tooltip";
import selectPaymentCount from "../../components/count.json";

const BASE_URL = process.env.REACT_APP_BASE_URL;
// const _ = require('lodash');

const genders = [
  { value: "G", label: "Male" },
  { value: "L", label: "Female" },
];

const specialCategories = [
  { value: "EWS", label: "EWS" },
  { value: "ORPHAN", label: "ORPHAN" },
  { value: "TFWS", label: "TFWS" },
  { value: "DEF", label: "DEFENCE" },
  { value: "PWD", label: "DISABILITY" },
  { value: "MI", label: "Minority" },
];

export default function MhtCet(props) {
  const universites = props.universites;
  const cities = props.cities;
  const navigate = useNavigate();
  const [percentile, setPercentile] = useState("");
  const [gender, setGender] = useState(null);
  const [category, setCategory] = useState(null);
  const [countChecked, setcountChecked] = useState("");
  const [isPremium, setIsPremium] = useState(props.ispremium);
  const [selectedCities, setSelectedCities] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState([]);
  const [selectedCount10, setSelectedCount10] = useState(null);
  const [selectedCount30, setSelectedCount30] = useState(null);
  const [selectedCount50, setSelectedCount50] = useState(null);
  const [selectedCount100, setSelectedCount100] = useState(null);
  const [selectedCount150, setSelectedCount150] = useState(null);
  const [selectedCount300, setSelectedCount300] = useState(null);
  const [userCount, setUserCount] = useState();
  const [isPending, setIsPending] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedSpecialCategory, setSelectedSpecialCategory] = useState(null);
  const [isHome, setIsHome] = useState(props.ispremium ? true : true);
  const [isState, setIsState] = useState(props.ispremium ? true : false);
  const [isOther, setIsOther] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const categoryData = props.categoryData;
  // const branchData = props.branchData;
  const [branchesData, setBranchesData] = useState([]);
  const [branches, setBranches] = useState([]);
  const [subBranch, setSubBranch] = useState([]);
  const [selectedSubBranch, setSelectedSubBranch] = useState(null);
  const [selectedCount, setSelectedCount] = useState("50");
  const [amount, setAmount] = useState(null);
  useEffect(() => {
    getBranches();
    setSelectedCities([]);
    setIsPremium(true);
    setCategories(categoryData);
  }, []);

  useEffect(() => {
    setCategories(categoryData);
  }, [categoryData?.length]);

  useEffect(() => {
    // Reset all counts to null
    setSelectedCount30("");
    setSelectedCount50("");
    setSelectedCount100("");
    setSelectedCount150("");
    setSelectedCount300("");

    // Set the selected count
    if (selectedCount === "30") {
      setSelectedCount30("30");
      const selectedItem = selectPaymentCount.selectPayment.find(
        (item) => item.count === 30
      );
      if (selectedItem) {
        setAmount(selectedItem.amount);
      } else {
        setAmount(0);
      }
    } else if (selectedCount === "50") {
      setSelectedCount50("50");
      const selectedItem = selectPaymentCount.selectPayment.find(
        (item) => item.count === 50
      );
      if (selectedItem) {
        setAmount(selectedItem.amount);
      } else {
        setAmount(0);
      }
    } else if (selectedCount === "150") {
      setSelectedCount150("150");
      const selectedItem = selectPaymentCount.selectPayment.find(
        (item) => item.count === 150
      );
      if (selectedItem) {
        setAmount(selectedItem.amount);
      } else {
        setAmount(0);
      }
    } else if (selectedCount === "300") {
      setSelectedCount300("300");
      const selectedItem = selectPaymentCount.selectPayment.find(
        (item) => item.count === 300
      );
      if (selectedItem) {
        setAmount(selectedItem.amount);
      } else {
        setAmount(0);
      }
    }
  }, [selectedCount]);

  const handleSubmit = async (e) => {
    // console.log("data");
    if (!token) {
      navigate("/login");
      return;
    }
    e.preventDefault();
    const cities = selectedCities.map((city) => city.value);
    const universitiesCities = selectedUniversity.map((city) => city.value);
    const allUnivercities = universitiesCities.flatMap((array) => array);
    if (
      selectedCount10 ||
      selectedCount30 ||
      selectedCount50 ||
      selectedCount100 ||
      selectedCount150 ||
      selectedCount300
    ) {
      setUserCount("");
      setcountChecked("");
    }
    if (
      !selectedCount10 &&
      !selectedCount30 &&
      !selectedCount50 & !selectedCount100 &&
      !selectedCount150 &&
      !selectedCount300
    ) {
      setcountChecked("count-unchecked");
      return;
    }

    setIsPending(true);
    props.setGender(gender != null ? gender.value : "");
    props.setPercentage(percentile);
    props.setUniversiity(selectedUniversity);
    props.setBranch(selectedBranch);
    props.setRelatedBranch(selectedSubBranch);
    props.setCategory(
      selectedSpecialCategory != null ? selectedSpecialCategory : category
    );
    const coll = {
      percentile,
      gender: gender != null ? gender.value : "",
      category: category?.value !== undefined ? category?.value : "",
      count: {
        selectedCount10,
        selectedCount30,
        selectedCount50,
        selectedCount100,
        selectedCount150,
        selectedCount300,
        userCount,
      },
      city: cities,
      branch: selectedSubBranch.map((branch) => branch.value),
      college_name: allUnivercities,
      specialCategary: selectedSpecialCategory?.value,
      universityType: { Home: isHome, State: isState, Other: isOther },
    };

    try {
      const response = await fetch(`${BASE_URL}/result/preferenceList`, {
        // const response = await fetch("https://api.itnexus.in/api/result/preferenceList", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(coll),
      });

      const data = await response.json();
      if (data) {
        // console.log("in mhtcet",data)
        props.setAmount(amount);
        props.setCollegeData(data);
        props.setTableName("MHT-CET Preference list generator");
        const targetDiv = document.getElementById("form-response");
        props?.setIsData(false);
        props.setMsg(
          "Thank You For Your Interest, Exciting new feature coming soon! Stay tuned for something truly attractive!"
        );
        if (targetDiv) {
          targetDiv.scrollIntoView({ behavior: "smooth" });
        }
        if (!data.length) {
          props.setMsg("There are no results As per your input");
        }
      } else {
        // props.setCollegeData(null);
        props.setTableName("MHT-CET Preference list generator");
        props?.setIsData(true);
        props.setMsg("There are no results As per your input");
      }
    } catch (err) {
      console.log("Error ", err);
      props?.setIsData(true);
      props.setMsg(
        "Thank You For Your Interest, Exciting new feature coming soon! Stay tuned for something truly attractive!"
      );
    }
    setIsPending(false);
    setSelectedCount30("30");
    setSelectedCount50("");
    setSelectedCount100("");
    setSelectedCount150("");
    setSelectedCount300("");
  };

  const handlePercentile = (e) => {
    const inputValue = e.target.value;
    console.log("input value = ", inputValue);
    // Validate input to allow only numbers and a single dot
    if (
      (!isNaN(inputValue) &&
        parseFloat(inputValue) >= 0 &&
        parseFloat(inputValue) <= 100) ||
      inputValue === ""
    ) {
      setPercentile(inputValue);
    }
  };

  function filterData(selection) {
    if (selection === "L") {
      return categoryData;
    } else if (selection === "G") {
      return categoryData.filter((item) => !item.startsWith("L"));
    } else {
      return categoryData;
    }
  }

  useEffect(() => {
    const data = filterData(gender?.value);
    setCategories(data);
  }, [gender?.value]);

  useEffect(() => {
    if (selectedSpecialCategory != null) {
      setIsHome(false);
      setIsState(false);
      setIsOther(false);
    }
  }, [selectedSpecialCategory]);

  useEffect(() => {
    // Initialize data as an empty array or null, depending on your use case
    let data = [];
    // Iterate through selectedBranch and gather data from branches object
    selectedBranch.forEach((branch) => {
      // Check if branch.label exists in branches object before accessing it
      if (branch && branches[branch.label]) {
        // If branches[branch.label] is an array, spread its elements into data
        if (Array.isArray(branches[branch.label])) {
          data.push(...branches[branch.label]);
        } else {
          // Otherwise, push branches[branch.label] as it is
          data.push(branches[branch.label]);
        }
      }
    });
    // Set the state with the collected data
    setSubBranch(data);
  }, [selectedBranch, branches]);

  const getBranches = async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/branches`, {
        // const response = await fetch("http://localhost:4000/api/public/branches", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data) {
        delete data["Undefined Group"];
        setBranchesData(Object.keys(data));
        setBranches(data);
      }
    } catch (err) {
      console.log("Error ", err);
    }
  };

  return (
    <>
      <form className="student-data form-class" onSubmit={handleSubmit}>
        <div className="w-80 xl:w-96 xl:p-3 md:w-96 md:p-3 lg:w-96 lg:p-3 2xl:w-96 2xl:p-3 3xl:w-96 3xl:p-3">
          <h3 className="form-heading-final">Fill the Details</h3>
          <label className="percentile align-middle items-center gap-12">
            <p className="text-sm">
              Name<span className="compulsory-feilds">*</span>
            </p>
            <input
              style={{ width: "65%" }}
              type="text"
              required
              onChange={(e) => {
                props.setForName(e.target.value);
              }}
              value={props.forName}
              placeholder="Enter Name"
            />
          </label>
          <label className="percentile align-middle items-center">
            <p className="text-sm">
              Percentile<span className="compulsory-feilds">*</span>
            </p>
            <input
              style={{ width: "65%" }}
              type="text"
              required
              onChange={handlePercentile}
              value={percentile}
              placeholder="Enter percentile"
            />
          </label>

          {selectedSpecialCategory == null ? (
            <label className="flex items-center relative gap-4">
              <span className="text-sm wTextForm">Gender</span>
              <Select
                className="sam spanclass"
                onChange={(option) => {
                  setGender(option);
                }}
                options={genders}
                placeholder="Select gender"
                value={gender}
                isSearchable={false}
              // isDisabled={!props.ispremium}
              />

              {gender !== null ? (
                <button
                  button
                  className=""
                  style={{ right: "-15px" }}
                  type="button"
                  onClick={() => {
                    setGender(null);
                  }}
                >
                  <FaRegCircleXmark color="red" />
                </button>
              ) : null}
            </label>
          ) : (
            <label
              className="category disabled input-disable align-middle items-center gap-4 relative"
              data-tip="Available with premium features"
            >
              <span className="text-sm wTextForm">Gender</span>
              <input type="text" className="spanclass" disabled />
            </label>
          )}

          {selectedSpecialCategory === null ||
            selectedSpecialCategory.value === "DEF" ||
            selectedSpecialCategory.value === "PWD" ? (
            <label className="category items-center relative gap-4">
              <span className="text-sm wTextForm">
                Cast Category<p className="compulsory-fields"></p>
              </span>
              <Select
                className="sam spanclass"
                onChange={(option) => {
                  setCategory(option);
                }}
                value={category}
                options={categories?.map((category) => ({
                  label: category,
                  value: category,
                }))}
                placeholder="Select category"
              />
              {category !== null ? (
                <button
                  onClick={() => {
                    setCategory(null);
                  }}
                >
                  <FaRegCircleXmark color="red" />
                </button>
              ) : null}
            </label>
          ) : (
            <label
              className="category disabled input-disable align-middle items-center gap-4 relative"
              data-tip="Available with premium features"
            >
              <span className="wTextForm">Cast Category</span>
              <input type="text" className="spanclass" disabled />
            </label>
          )}

          {isPremium ? (
            <label className="branch-final align-middle items-center gap-4 relative">
              <span className="text-sm wTextForm">
                Branch <span className="compulsory-feilds">*</span>
              </span>
              <Select
                className="spanclass"
                onChange={(option) => {
                  setSubBranch([]);
                  // setSelectedSubBranch(null)
                  setSelectedBranch(option);
                }}
                options={branchesData?.map((branches) => ({
                  label: branches,
                  value: branches,
                }))}
                isMulti
                value={selectedBranch}
                placeholder="Select Branch"
                required
              />
              {selectedBranch.length ? (
                <button
                  button
                  className=""
                  style={{ right: "-20px" }}
                  type="button"
                  onClick={() => {
                    setSelectedBranch([]);
                    setSelectedSubBranch(null);
                  }}
                >
                  <FaRegCircleXmark color="red" />
                </button>
              ) : null}
            </label>
          ) : (
            <label className="disabled branch-final">
              <p>Branch</p>
              <input className="spanclass" type="text" disabled />
            </label>
          )}

          {isPremium ? (
            <label className="branch-final align-middle items-center gap-4 relative">
              <span className="text-sm wTextForm">
                Related Branch<span className="compulsory-feilds">*</span>
              </span>
              <Select
                className="spanclass"
                onChange={(option) => {
                  setSelectedSubBranch(option);
                }}
                options={subBranch?.map((branch) => ({
                  label: `${branch.branch} (${branch.count})`,
                  value: branch.branch,
                }))}
                isMulti
                value={selectedSubBranch}
                placeholder="Select Branch"
                required
              />
              {selectedSubBranch !== null ? (
                <button
                  button
                  className=""
                  style={{ right: "-20px" }}
                  type="button"
                  onClick={() => {
                    setSelectedSubBranch(null);
                  }}
                >
                  <FaRegCircleXmark color="red" />
                </button>
              ) : null}
            </label>
          ) : (
            <label className="disabled branch-final">
              <p>Related Branch</p>
              <input className="spanclass" type="text" disabled />
            </label>
          )}

          {isPremium ? (
            <label className="uni-final items-center gap-4">
              <p className="text-sm wTextForm">University</p>
              <Select
                className="spanclass"
                onChange={(option) => {
                  setSelectedUniversity(option);
                }}
                options={universites.map((university) => ({
                  label: university,
                  value: university,
                }))}
                isMulti
                value={selectedUniversity}
                placeholder="Select University"
              />
            </label>
          ) : (
            <label className="disabled uni-final">
              <p>University</p>
              <input className="spanclass" type="text" disabled />
            </label>
          )}
          {isPremium ? (
            <label className="uni-final items-center gap-4">
              <p className="text-sm wTextForm">City</p>
              <Select
                className="spanclass"
                onChange={(option) => {
                  setSelectedCities(option);
                }}
                options={cities?.map((city) => ({
                  label: city,
                  value: city,
                }))}
                isMulti
                value={selectedCities}
                placeholder="Select City"
              />
            </label>
          ) : (
            <label className="disabled uni-final">
              <p>City</p>
              <input className="spanclass" type="text" disabled />
            </label>
          )}

          {isPremium ? (
            <label className="flex gap-4 items-center relative align-baseline">
              <p className="text-sm wTextForm">
                Special category<span className="compulsory-fields"></span>
              </p>
              <Select
                className="sam spanclass"
                onChange={(option) => {
                  setSelectedSpecialCategory(option);
                }}
                value={selectedSpecialCategory}
                options={specialCategories}
                placeholder="Select Category"
              // isDisabled={!props.ispremium}
              />
              {selectedSpecialCategory !== null ? (
                <button
                  button
                  className=""
                  style={{ right: "-15px" }}
                  type="button"
                  onClick={() => {
                    setSelectedSpecialCategory(null);
                  }}
                >
                  <FaRegCircleXmark color="red" />
                </button>
              ) : null}
            </label>
          ) : (
            <label
              className="category disabled input-disable"
              data-tip="Available with premium features"
            >
              <span className="text-sm wTextForm">Special category</span>
              <input type="text" className="spanclass" disabled />
            </label>
          )}

          <div className="flex items-center my-3 gap-4">
            <p className="text-sm wTextForm">University type</p>
            <div className="flex mt-1 gap-5 items-center">
              <div className="text-center">
                <input
                  type="checkbox"
                  className="scale-125"
                  // checked={isState}
                  checked={true}
                  onChange={() => setIsState(true)}
                // disabled={!props.ispremium || selectedSpecialCategory != null}
                />
                <strong
                  data-tooltip-id="su-tooltip"
                  onClick={() => {
                    !props.ispremium ||
                      (selectedSpecialCategory === null && setIsState(true));
                  }}
                  className="cursor-pointer text-sm"
                >
                  State
                </strong>
                <Tooltip id="su-tooltip">State University</Tooltip>
              </div>
              <div className="text-center" data-tooltip-id="hu-tooltip">
                <label className=" mb-0">
                  <input
                    type="radio"
                    checked={isHome}
                    onChange={() => {
                      setIsHome(true);
                      setIsOther(false);
                    }}
                  // disabled={!props.ispremium || selectedSpecialCategory != null}
                  />
                  Home
                </label>
                <Tooltip id="hu-tooltip">Home University</Tooltip>
              </div>
              <div className="text-center">
                <label className="mb-0" data-tooltip-id="ohu-tooltip">
                  <input
                    type="radio"
                    checked={isOther}
                    onChange={() => {
                      setIsHome(false);
                      setIsOther(true);
                    }}
                  // disabled={!props.ispremium || selectedSpecialCategory != null}
                  />
                  Other
                </label>
                <Tooltip id="ohu-tooltip">Other Than Home University</Tooltip>
              </div>
            </div>
          </div>
          {isPremium ? (
            <label className={`count ${countChecked}`}>
              <div className="flex gap-14">
                <span className="text-sm">
                  Count
                  <span className="compulsory-feilds c-field-for-count ml-0">
                    *
                  </span>
                </span>
                <div className="flex justify-start align-middle ">
                  <div className="flex gap-8">
                    {selectPaymentCount.selectPayment.map((item) => (
                      <div key={item.count} className="flex gap-2 ">
                        <input
                          type="radio"
                          value={item.count}
                          checked={selectedCount === item.count.toString()}
                          onChange={() =>
                            setSelectedCount(item.count.toString())
                          }
                        />
                        <label className="counts">{item.count}</label>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {countChecked === "count-unchecked" && (
                <div className="error-message" style={{ color: "red" }}>
                  Please select at least one option.
                </div>
              )}
            </label>
          ) : (
            <label className="count disabled">
              <div className="count-child count-disabled-final">
                Count
                <input
                  className="user-count "
                  type="number"
                  placeholder="count"
                  value={userCount}
                  min="0"
                  disabled
                  onChange={(e) => {
                    setUserCount(e.target.value);
                  }}
                />
              </div>
            </label>
          )}
          {!isPending && <button className="search-button">Search</button>}
          {isPending && <button className="search-button">Searching..</button>}
        </div>
      </form>
    </>
  );
}
