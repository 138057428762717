import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    signupData: null,
    loading: false,
    token: localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null,
    OtpError: false,
    otpVerifyError: false,
    user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null
};

const authSlice = createSlice({
    name: "auth",
    initialState: initialState,
    reducers: {
        setSignupData(state, value) {
            state.signupData = value.payload;
        },
        setOtpError(state, value) {
            state.OtpError = value.payload;
        },
        setLoading(state, value) {
            state.loading = value.payload;
        },
        setOtpVerifyError(state, value) {
            state.loading = value.payload;
        },
        setToken(state, value) {
            state.token = value.payload;
        },
        setUser(state, value) {
            state.user = value.payload
        }
    }
})

export const { setSignupData, setLoading, setToken, setOtpError, setOtpVerifyError, setUser } = authSlice.actions;

export default authSlice.reducer;