import "./NewAboutus.css"
import mission from "../Assets/img/Mission.png";
import vission from "../Assets/img/Vision.png";
import NewFooter from "../NewFooter/NewFooter";
const NewAbout = () => {
  return (
    <div className="container-fluid">
      <div className="row about-full-div">
        <div className="col-12 col-md-6 mb-4">
          <div className="left-box-about">
            <div className="inside-box-div desktop-view">
              <img className="image-class" src={vission} alt="vision"/>
              <h2 className="image-head">Vision</h2>
              <p className="para-class-about">We envision a future where every student has access to information and support, they need to make informed decisions about their higher education journey. By leveraging technology and expert knowledge, we aim to make the admissions process transparent, accessible, and stress-free.</p>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-6">
          <div className="right-box-about">
            <div className="inside-box-div desktop-view">
              <img className="image-class-mission" src={mission} alt="mission"/>
              <h2 className="image-head">Mission</h2>
              <p className="para-class-about">At Campus Connect, our mission is to empower students and parents in Maharashtra by simplifying the college admission process. We strive to provide comprehensive support, personalized guidance, and valuable resources to help students achieve their educational goals.</p>
            </div>
          </div>
        </div>
        <div className="our-team-class">
          <h2 className="image-head">Our Team</h2>
          <p className="para-class-about our-team-class marginb-6 mobile-pra-class">The Campus Connect team is comprised of seasoned educators, career counselors, and technology experts who are passionate about education. Our combined expertise ensures that you receive the highest quality guidance and support.</p>
        </div>
      </div>
      <NewFooter />
    </div>
  );
};

export default NewAbout;
