import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { NavLink, useNavigate } from 'react-router-dom';
import OtpInput from "react-otp-input";
import { HiArrowLongLeft } from 'react-icons/hi2'
import { GiBackwardTime } from "react-icons/gi";
import { sendOtp } from '../services/operations/authAPI';
import { signUp } from '../services/operations/authAPI';
import "../style/verifyEmail.css";

const VerifyEmail = () => {
    const { signupData, loading } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [otp, setOtp] = useState("");

    useEffect(() => {
        if (!signupData) {
            navigate("/signup");
        }
    }, [signupData, navigate])

    const handleOnSubmit = (e) => {
        e.preventDefault();
        const {
            firstName,
            lastName,
            contactNumber,
            email,
            password,
            confirmPassword
        } = signupData;
        dispatch(signUp(firstName, lastName, email, password, confirmPassword, contactNumber, otp, navigate));
    }

    return (
        <div className='max-w-[500px] w-[50%] mx-auto mt-[8rem] flex items-center justify-center'>
            {
                loading ? (
                    <div className='w-full h-400px flex items-center justify-center'>
                        Loading....
                    </div>
                ) : (
                    <div className='max-w-[508px] p-8 flex flex-col gap-3 border rounded-md border-richblack-400 verify'>
                        <h1 className='font-semibold text-3xl text-richblack-800'>OTP</h1>
                        <p className='font-normal text-base text-richblack-300 mb-4'>A verification code has been sent to you. Enter the code below${signupData.newEmail}</p>

                        <form onSubmit={handleOnSubmit} className='form outline-none border-none shadow-none otpinput'>
                            <div className='flex justify-center mx-auto border-none'>
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    renderInput={(props) => <input {...props} />}
                                    separator={<span style={{ width: "9px", margin: "2px" }}>-</span>}
                                    inputStyle={{
                                        border: "1px solid #161D29",
                                        borderRadius: "8px",
                                        width: "50px",
                                        height: "50px",
                                        fontSize: "16px",
                                        fontWeight: "500",
                                        caretColor: "blue",
                                        margin: "0px 4px",
                                    }}
                                    focusStyle={{
                                        outline: "none",
                                    }}
                                    isInputNum={true}
                                    shouldAutoFocus={true}

                                // inputStyle="otp-input"
                                />
                            </div>

                            <button type='submit' className='bg-yellow-25 px-8 py-1 mt-3 flex items-center justify-center rounded-md hover:bg-yellow-5'>
                                <p className='text-richblack-800 font-semibold mb-1'>Verify Email</p>
                            </button>
                        </form>


                        {/* <div>
                    <span>
                        <NavLink>
                            <span className='flex gap-2 items-center'>
                                <HiArrowLongLeft/>
                                <p className='mb-1'>Back To Login</p>
                            </span>
                        </NavLink>
                    </span>
                </div> */}
                        <div className='flex justify-between flex-col w-[90%] mx-auto lg:flex-row'>
                            <span>
                                <NavLink to="/signup">
                                    <span className='flex items-center max-w-maxContent gap-2'>
                                        <HiArrowLongLeft></HiArrowLongLeft>
                                        <p className='mb-1'>Back To Login</p>
                                    </span>
                                </NavLink>
                            </span>

                            <span className=''>
                                <NavLink>
                                    <span onClick={() => dispatch(sendOtp(signupData.email, navigate))} className='flex items-center gap-2 text-blue-300'>
                                        <GiBackwardTime />
                                        <p className='mb-1'>Resend it</p>
                                    </span>
                                </NavLink>
                            </span>
                        </div>
                    </div>
                )
            }
        </div>
    )
}


export default VerifyEmail;