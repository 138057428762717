
import { useState } from "react";
import "./NewContact.css";
import { useDispatch } from "react-redux"
import { contactUs } from '../../services/operations/authAPI';
import { useNavigate } from "react-router-dom";
import NewFooter from "../NewFooter/NewFooter";

const NewContactUs = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [contactFormData, setcontactFormData] = useState({
        firstName: '',
        lastName: '',
        newEmail: '',
        contactNumber: '',
        additionalMessage: ''
    });

    const [errors, setErrors] = useState({});

    const handleChange = (e) => {

        const { name, value } = e.target;
        setcontactFormData({
            ...contactFormData,
            [name]: value
        });

    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const validatePhoneNumber = (number) => {
        return /^\d{10}$/.test(number);
    };

    const handleSubmit = (e) => {

        e.preventDefault();

        const newErrors = {};

        if (!validateEmail(contactFormData.newEmail)) {
            console.log("jbjbj")
            newErrors.newEmail = "Invalid email address";
        }

        if (!validatePhoneNumber(contactFormData.contactNumber)) {
            newErrors.contactNumber = "Phone number must be exactly 10 digits";
        }

        setErrors(newErrors);

        if (Object.keys(newErrors).length === 0) {
            dispatch(contactUs(contactFormData, navigate));
            setcontactFormData({
                additionalMessage: "",
                contactNumber: "",
                firstName: "",
                newPassword: "",
                lastName: "",
                newEmail: ""
            })
        }
    };

    return (
        <div className="contactUs">
            <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 items-center p-8">
                <div className="col-span-6 sm:col-span-6 xs:col-span-6 sm:order-2 flex items-center justify-center flex-col xs:py-16 2xl:px-12">
                    <p><strong>GDB Learning Solutions Private Limited</strong></p>
                    <p className="text-wrap">S.No.22/1, Flat No.101, B-Building, B-Wing, Kamalacity, Pune, Pune 411046, Maharashtra</p>
                    <p className="text-wrap">Mobile No. - +91 99229 13434</p>
                    <p className="text-wrap">Email - campusconnectapps@gmail.com</p>
                 
                    <hr />
                    <p> <strong>Technology Partner </strong></p>
                    <p><strong>Innovative Technexus LLP</strong></p>
                    <p className="text-wrap">Office No. 20, Saitaj Complex, S. No. 82/6/1, nr. 1, Kanifnath Housing Society, Guruganesh Nagar, Kothrud, Pune, Maharashtra 411029</p>
                 
                </div>
                <div className='col-span-6 sm:col-span-6 xs:col-span-6 sm:order-2 flex items-center justify-center flex-col xs:py-16'>
                    <div>
                        <h1 className='text-2xl text-[#446282] font-semibold mb-4'>Send us message</h1>
                        <form className="contactus-form" onSubmit={handleSubmit}>
                            <div className='form-name'>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='firstName'
                                        value={contactFormData.firstName}
                                        onChange={handleChange}
                                        placeholder='Your First Name'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)", background: "#F6F6F6"
                                        }}
                                    />
                                </label>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='lastName'
                                        value={contactFormData.lastName}
                                        onChange={handleChange}
                                        placeholder='Your Last Name'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)", background: "#F6F6F6"
                                        }}
                                    />
                                </label>
                            </div>
                            <div className='form-name'>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        name='newEmail'
                                        value={contactFormData.newEmail}
                                        onChange={handleChange}
                                        placeholder='Your mail'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)", background: "#F6F6F6"
                                        }}
                                    />
                                    {errors.newEmail && <span className="error-class" style={{ color: 'red' }}>{errors.newEmail}</span>}
                                </label>
                                <label>
                                    <input
                                        required
                                        type='number'
                                        name='contactNumber'
                                        placeholder='+00 0000000'
                                        value={contactFormData.contactNumber}
                                        onChange={handleChange}
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)", background: "#F6F6F6"
                                        }}
                                    />
                                    {errors.contactNumber && <span className="error-class" style={{ color: 'red' }}>{errors.contactNumber}</span>}
                                </label>
                            </div >
                            <div className='form-name addition-details'>
                                <label>
                                    <input
                                        required
                                        type='text'
                                        className="addition-details"
                                        name='additionalMessage'
                                        value={contactFormData.additionalMessage}
                                        onChange={handleChange}
                                        placeholder='Additional Message'
                                        style={{
                                            boxShadow: "inset 0px -1px 0px rgba(255, 255, 255, 0.18)", background: "#F6F6F6"

                                        }}
                                    />
                                </label>
                            </div>
                            <div className="button-anchor-container-for-next-contact">
                                <button type="submit" className="btn-contact">Send</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <NewFooter />
        </div>
    )
};


export default NewContactUs;
