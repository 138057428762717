import NewFooter from "../../NewFooter/NewFooter";

export default function TermsAndConditions() {
  return (
    <div className="container-fluid privacyPolicy">
      <div className="mx-10 my-5">
        <h1>Terms & Conditions</h1>
        <div className="content pt-4">
          <h3>Acceptance of Terms</h3>
          <p>
            By using our Site and the payment services, you agree to comply with
            and be bound by these Terms.
          </p>
          <p>
            We may update these Terms from time to time, and your continued use
            of the Site constitutes your acceptance of the updated Terms.
          </p>
        </div>
        <div className="content">
          <h3>Payment Processing</h3>
          <p>
            <b>Payment Gateway:</b> We use Razorpay as our payment gateway provider to
            process transactions. By making a payment, you agree to Razorpay’s
            terms and conditions and privacy policy.
          </p>
          <p>
            <b>Payment Methods:</b> We accept various payment methods, including
            credit/debit cards, net banking, and digital wallets, as supported
            by Razorpay.
          </p>
        </div>
        <div className="content">
          <h3>Fees and Charges</h3>
          <p>
            <b>Transaction Fees:</b> Transaction fees may apply as per Razorpay’s
            pricing structure. These fees are subject to change and are the
            responsibility of the user.
          </p>
          <p><b>Currency:</b> All transactions will be processed in Rupees.</p>
        </div>
        <div className="content">
          <h3>Order Processing and Confirmation</h3>
          <p>
            <b>Order Confirmation:</b> Once you complete a payment, you will receive an
            order confirmation email detailing your purchase. This confirmation
            is not a guarantee of product availability.
          </p>
          <p>
            <b>Order Processing:</b> We will process your order once payment has been
            successfully completed. Any issues with payment processing will be
            communicated to you via email.
          </p>
        </div>

        <div className="content">
          <h3>Refunds and Cancellations</h3>
          <p>
            <b>Refund Policy:</b> Refunds, if applicable, will be processed according
            to our  <a className="url" href="/refund-policy">Refund Policy Link</a>. Please review this policy for details
            on eligibility and processing times.
          </p>
          <p>
            <b>Cancellation:</b> You may cancel an order in accordance with our
            cancellation policy. If a payment is cancelled, you may be subject
            to fees as outlined in our cancellation policy.
          </p>
          <p></p>
        </div>
        <div className="content">
          <h3>User Responsibilities</h3>
          <p>
            <b>Account Information:</b> You are responsible for providing accurate and
            complete information when making a payment. You must ensure that
            your payment method details are correct and up-to-date.
          </p>
          <p>
            <b>Security:</b> You are responsible for keeping your payment details
            secure and notifying us of any unauthorized transactions
            immediately.
          </p>
        </div>
        <div className="content">
          <h3>Dispute Resolution</h3>
          <p>
            <b>Disputes:</b> Any disputes related to payments or transactions should be
            reported to us at [Your Contact Information]. We will work with
            Razorpay to resolve any issues that arise.
          </p>

          <p>
            <b>Limitation of Liability:</b> To the fullest extent permitted by law, we
            shall not be liable for any direct, indirect, incidental, or
            consequential damages arising from or related to payment processing.
          </p>
        </div>
        <div className="content">
          <h3> Privacy Policy</h3>
          <p>
            <b>Data Collection:</b> We collect and use your personal information in
            accordance with our Privacy Policy. By using our payment services,
            you consent to our Privacy Policy and the processing of your
            information by Razorpay.
          </p>
        </div>
        <div className="content">
          <h3>Modifications and Termination</h3>
          <p>
            <b>Modifications:</b> We may modify or terminate our payment services at
            any time. We will notify you of any significant changes to these
            Terms. Termination: We may suspend or terminate your access to the
            payment services if you breach these Terms.
          </p>
        </div>
        <di className="content">
          <h3>Governing Law</h3>
          <p>
            {" "}
            These Terms are governed by and construed in accordance with the
            laws of India, without regard to its conflict of law principles.
          </p>
        </di>
        <di className="content">
          <h3> Contact Us</h3>
          <p>
            If you have any questions or concerns about these Terms or payment
            processing, please contact us at{" "}
          </p>
          <p>
            Campus Connect a Product by GDB Learning Solutions Private Limited<br></br>
            S.No.22/1, Flat No.101, B-Building, B-Wing, Kamalacity, Pune, Pune<br></br>
            411046, Maharashtra Email -
            <a className="url" href="mailto:campusconnectapps@gmail.com">
            campusconnectapps@gmail.com
            </a>{" "}
            <br></br>
        
          </p>
          <p>
        Contact:{" "}
        <a className="url" href="tel:9579938156">
        +91 99229 13434
        </a>
      </p>
        </di>
      </div>
      <NewFooter />
    </div>
  );
}
