import NewFooter from "../NewFooter/NewFooter";

export default function RefundPolicy() {
  return (
    <div className="container-fluid privacyPolicy">
      <div className="mx-10 my-5">
        <h1>Refund and cancellation policy</h1>
        <p className="my-3 info">
          Welcome to GDB LEARNING SOLUTIONS PVT LTD a Product by GDB LEARNING
          SOLUTIONS PVT LTD. We are committed to providing our customers with a
          fair and transparent refund policy. Please read the following
          guidelines carefully before making any purchases.
        </p>

        <div className="content">
          <h3>Eligibility for Refunds</h3>
          <p>
            Refund requests must be made within 7 days of the original purchase
            date.
          </p>
          <p>
            Only the original purchaser of the service is eligible for a refund.
          </p>
          <p>
            Refunds are applicable only for services that have not been used or
            availed.
          </p>
        </div>
        <div className="content">
          <h3>Non-Refundable Services</h3>
          <p>
            One-on-one counseling sessions that have already been conducted.
          </p>
          <p>
            Digital products or services that have been downloaded or accessed.
          </p>
        </div>
        <div className="content">
          <h3>Process for Requesting a Refund</h3>
          <p>
            To request a refund, please contact our support team at{" "}
            <a className="url" href="mailto:campusconnectapps@gmail.com">
            campusconnectapps@gmail.com
            </a>{" "}
            with your order details and reason for the refund.
          </p>
          <p>
            Our team will review your request and respond within 3-5 business
            days.
          </p>
          <p>
            {" "}
            Approved refunds will be processed within 7-10 business days and
            will be credited back to the original method of payment.
          </p>
        </div>
        <div className="content">
          <h3>Changes to the Refund Policy</h3>
          <p>
            GDB LEARNING SOLUTIONS PVT LTD reserves the right to modify or
            update this refund policy at any time without prior notice. Changes
            will be effective immediately upon posting on our website.
          </p>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}
