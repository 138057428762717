import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import './index.css'
import { Toaster } from "react-hot-toast";
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from "./reducer"
import { Provider } from 'react-redux';

const root = ReactDOM.createRoot(document.getElementById('root'));

const store = configureStore({
  reducer: rootReducer,
});


root.render(
  <Provider store= {store}>
      <BrowserRouter>
          <AuthContextProvider>
          <App />
          <Toaster/>
          </AuthContextProvider>
        </BrowserRouter>
  </Provider>
  
); 

