import { useState, useEffect } from "react";
import "./FinalForm.css";
import React from "react";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import NewFooter from "../NewFooter/NewFooter";
import BranchWise from "../Branch-wise/Branch-wise";
import BranchTopCollage from "../BranchTopCollage/BranchTopCollage";
import logopng from "../../components/Assets/img/logoBg.png";
import { useSelector } from "react-redux";
import MhtCet from "../Mht-Cet/Mht-cet";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import axios from "axios";
import { setUser } from "../../slice/authSlice";
import RenderRazorpay from "../RazorPayPayment/RazorPayPayment";
import { useNavigate } from "react-router-dom";
import { FaCircleXmark, FaRegCircleXmark } from "react-icons/fa6";
const BASE_URL = process.env.REACT_APP_BASE_URL;
const _ = require("lodash");

export default function FinalForm() {
  const BrachDescription =
    "Welcome to our Branch-Wise Cut Off section, designed to provide you with crucial information about the minimum scores required to secure admission in various branches of engineering across top colleges. Understanding branch-wise cut offs is essential for making informed decisions about your education and future career.";

  const BranchTopList =
    "Welcome to the Branch-Wise Top College List section! This feature is designed to help you identify the best colleges for your chosen branch of engineering.";

  const PreferenceList =
    "Providing your exam score helps us recommend colleges and admissions opportunities that match your academic profile. If you don't have your actual score yet, you can enter your expected score. This will still allow us to give you personalized recommendations for colleges and programs suited to your qualifications.";

  const BranchTitle = "Branch wise cutoff list";

  const BranchTopListTitle = "Branch-Wise Top College List";

  const PreferenceListTitle = "Preference list generator";
  const [collegeData, setCollegeData] = useState(null);
  const [activeForm, setactiveform] = useState("generateList");
  const [tableName, setTableName] = useState(
    "MHT-CET Preference list generator"
  );
  const [universites, setUniversites] = useState([]);
  const [categoryData, setCategoryData] = useState();
  const [branchData, setbranchData] = useState();
  const [cities, setCities] = useState();
  const [isData, setisData] = useState(false);
  const [msg, setMsg] = useState("");
  const [titel, setTitle] = useState(BranchTitle);
  const [description, setDescription] = useState(BrachDescription);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const [role, setRole] = useState(user.role);
  const removeKeywords = ["PWD", "DEF", "EWS", "ORPHAN", "TFWS"];
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [orderDetails, setOrderDetails] = useState({
    order_id: null,
    currency: null,
    amount: null,
  });

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const dispatch = useDispatch();
  // PDF information
  const [percentage, setPercentage] = useState("");
  const [gender, setGender] = useState("");
  const [forName, setForName] = useState("");
  const [university, setUniversiity] = useState(null);
  const [branch, setBranch] = useState(null);
  const [relatedBranch, setRelatedBranch] = useState(null);
  const [category, setCategory] = useState(null);
  const [amount, setAmount] = useState(null);

  const clearState = () => {
    setBranch(null);
    setGender("");
    setCategory(null);
    setPercentage("");
    setUniversiity(null);
    setRelatedBranch(null);
    setForName("");
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    getUniversity();
    getDetails();
    getCities();
  }, []);

  const showMore = async () => {
    const data = {
      userId: user._id,
      interested: true,
    };
    try {
      const response = await fetch(`${baseUrl}/auth/user-interested`, {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      const responseData = await response.json();
      console.log("Response data:", responseData);
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const createRazorPayOrder = async () => {
    try {
      const response = await axios.post(
        `${baseUrl}/payment/create`,
        {
          amount: amount,
          currency: "INR",
          keyId: process.env.REACT_APP_RAZORPAY_KEY_ID,
          KeySecret: process.env.REACT_APP_RAZORPAY_KEY_SECRET,
          user_id: user._id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setDisplayRazorpay(true);
        setOrderDetails(response.data);
        // handleRenderRazorpay()
      }
    } catch (error) {
      setDisplayRazorpay(false);
      setOrderDetails(null);
      togglePopup();
      console.error("Error creating RazorPay order:", error);
      toast.error("Failed to create RazorPay order. Please try again later.");
    }
    await showMore();
  };

  const triggerDownload = async () => {
    await downloadData(collegeData);
  };

  // const downloadPdf = async (formData) => {
  //   try {
  //     const response = await fetch(`${baseUrl}/public/downloadPdf`, {
  //       method: "PATCH",

  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //       body: formData,
  //     });
  //     const responseData = await response.json();
  //     if (responseData && responseData.user) {
  //       console.log("Response data:", responseData.user);
  //       localStorage.setItem("user", JSON.stringify(responseData.user));
  //       dispatch(setUser(responseData?.user));
  //       return responseData;
  //     } else {
  //       console.error("User data not found in response.");
  //       navigate("/");
  //       throw new Error("User data not found in response.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error.message);
  //     navigate("/");
  //     throw error;
  //   }
  // };

  const downloadData = async (collegeData) => {
    const doc = new jsPDF();
    const headers = [
      [
        "Sr.no.",
        activeForm !== "topCollage" ? "Branch ID" : null,
        "Branch Name",
        activeForm !== "topCollage" ? "Branch" : null,
        activeForm !== "topCollage" ? "Percentile" : null,
        "Closing Rank",
        "City",
      ],
    ];

    let rows;
    let check = localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null;
    console.log("", check);
    let premium;
    if (role == "SA") {
      premium = true;
    } else {
      premium = check?.premium ?? false;
    }

    if (activeForm === "generateList") {
      const limitedCollegeData = collegeData.slice(0, 3);
      rows = premium
        ? collegeData.map((item, i) => [
          i + 1,
          activeForm !== "topCollage" ? item.branch_id : null,
          item.college_name,
          activeForm !== "topCollage" ? item.branch_name : null,
          activeForm !== "topCollage" ? item.closing_percentile_2024 : null,
          activeForm !== "topCollage" ? item.closing_rank_2024 : null,
          item.city?.charAt(0).toUpperCase() + item.city?.slice(1),
        ])
        : limitedCollegeData.map((item, i) => [
          i + 1,
          activeForm !== "topCollage" ? item.branch_id : null,
          item.college_name,
          activeForm !== "topCollage" ? item.branch_name : null,
          activeForm !== "topCollage" ? item.closing_percentile_2024 : null,
          activeForm !== "topCollage" ? item.closing_rank_2024 : null,
          item.city?.charAt(0).toUpperCase() + item.city?.slice(1),
        ]);
    } else {
      rows = collegeData.map((item, i) => [
        i + 1,
        activeForm !== "topCollage" ? item.branch_id : null,
        item.college_name,
        activeForm !== "topCollage" ? item.branch_name : null,
        activeForm !== "topCollage" ? item.closing_percentile_2024 : null,
        activeForm !== "topCollage" ? item.closing_rank_2024 : null,
        item.city?.charAt(0).toUpperCase() + item.city?.slice(1),
      ]);
    }

    // Limit the collegeData to the first 3 entries

    const addSearchByText = (text) => {
      const textLines = doc.splitTextToSize(text, 180);
      textLines.forEach((line) => {
        doc.text(line, 14, currentY);
        currentY += 6; // Move down by 6 units for each line
      });
    };

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const now = new Date();
    const formattedDate = now.toLocaleString("en-US", options);
    doc.setFontSize(10);
    doc.text(`Name: ${user?.firstName} ${user?.lastName}`, 14, 10);
    doc.text(`For: ${forName}`, 14, 16);
    doc.text(`Date: ${formattedDate}`, 14, 22);
    let currentY = 28;
    doc.text(`Search By:`, 14, currentY);
    currentY += 6; // Move down by 6 units for the next line
    if (percentage) {
      doc.text(` Percentile: ${percentage}`, 14, currentY);
      currentY += 6;
    }
    if (gender) {
      doc.text(` Gender: ${gender == "L" ? "Female" : "Male"}`, 14, currentY);
      currentY += 6;
    }
    if (category) {
      doc.text(`category: ${category.value}`, 14, currentY);
      currentY += 6;
    }
    if (university && university.length > 0) {
      const universityText = `University: ${university
        .map((u) => u.value)
        .join(", ")}`;
      addSearchByText(universityText);
      currentY += 6;
    }
    if (branch && branch.length > 0) {
      addSearchByText(`Branch: ${branch.map((b) => b.value).join(", ")}`);
    } else {
      if (branch) {
        doc.text(`Branch: ${branch.value}`, 14, currentY);
        currentY += 6;
      }
    }
    if (relatedBranch && relatedBranch.length > 0) {
      const relatedBranchText = `Related Branch: ${relatedBranch
        .map((u) => u.value)
        .join(", ")}`;
      addSearchByText(relatedBranchText);
    } else {
      if (relatedBranch) {
        doc.text(`Related Branch: ${relatedBranch.value}`, 14, currentY);
        currentY += 6;
      }
    }

    doc.autoTable({ startY: currentY, head: headers, body: rows });
    var pageCount = doc.internal.getNumberOfPages();
    let footerHeight = 50; // Increase footer height as needed
    const disclaimerText = `Disclaimer: The information, predictions, and content provided by Campus Connect, including college predictor and branch-wise prediction tools, are intended solely for reference purposes. While we strive to ensure the accuracy and reliability of the data presented, we do not guarantee or assure that the results and outcomes shown are 100% accurate or conclusive. Candidates are strongly advised to independently verify and validate all information before making any decisions based on the provided.`;
    doc.setFontSize(8);
    doc.setTextColor("black");
    // const textWidth = doc.internal.pageSize.width - 30;
    for (let i = 1; i <= doc.internal.getNumberOfPages(); i++) {
      doc.setPage(i);
      doc.text("", 10, doc.internal.pageSize.height - footerHeight + 50);
      let wrappedText = doc.splitTextToSize(
        disclaimerText,
        doc.internal.pageSize.width - 30
      );
      doc.text(14, doc.internal.pageSize.height - 13, wrappedText);
      console.log(
        doc.internal.pageSize.height,
        doc.internal.pageSize.height - 13
      );
    }
    const watermarkImg = logopng; // Customize your watermark text here
    // Set static position for watermark
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    const startX = pageWidth / 2;
    const startY = pageHeight / 2;
    const fontSize = 10;
    // Calculate text width and height
    // Font size of the watermark
    doc.setFontSize(fontSize);
    doc.setTextColor(200); // Color of the watermark (light gray)
    doc.setFont("helvetica", "italic"); // Set font and style
    const textWidth = 100;
    const textHeight = 60;
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      // Draw the watermark text at the static position
      doc.addImage(
        watermarkImg,
        startX,
        startY,
        textWidth,
        textHeight,
        "",
        "FAST",
        45
      );
    }
    const pdfBlob = doc.output('blob');
    const formData = new FormData();
    formData.append('pdfFile', pdfBlob, 'campus-connect.pdf');
    formData.append('userId', user._id);
    try {
      const response = await fetch(`${baseUrl}/payment/download`, {
        method: 'PATCH',
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const responseData = await response.json();
      if (responseData && responseData.user) {
        console.log("Response data:", responseData.user);
        localStorage.setItem("user", JSON.stringify(responseData.user));
        dispatch(setUser(responseData?.user));
        toast.success('Your PDF has been sent to your email. Thank you!');
        return responseData;
      } else {
        console.error("User data not found in response.");
        navigate("/");
        toast.error("User data not found in response.")
        throw new Error("User data not found in response.");
      }
    } catch (error) {
      console.error("Error:", error.message);
      navigate("/");
      throw error;
    }
  };

  const getUniversity = async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/universities`, {
        // const response = await fetch("http://localhost:4000/api/public/universities", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data) {
        setUniversites(data);
      } else {
      }
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const getDetails = async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/universityDetails`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data) {
        const genralCategaryData = processCategories(data[0]?.categories);
        setbranchData(data[0].branches);
        const categories = genralCategaryData.filter(
          (category) => category !== "MI"
        );
        setCategoryData(categories.sort());
      } else {
      }
    } catch (err) {
      console.log("Error ", err);
    }
  };

  const getCities = async () => {
    try {
      const response = await fetch(`${BASE_URL}/public/cities`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      if (data) {
        setCities(data[0].cities?.sort());
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const processCategories = (allCategories) => {
    const filteredCategories = _.filter(allCategories, (category) => {
      return !_.some(removeKeywords, (keyword) =>
        _.includes(category.toUpperCase(), keyword)
      );
    });

    const processedCategories = filteredCategories.map((str) => {
      if (str.length > 2) {
        const core = str.substring(1, str.length - 1);
        return core === "OPE" ? "OPEN" : core;
      }
      return str;
    });
    return _.uniq(processedCategories);
  };

  useEffect(() => {
    setMsg(msg);
  }, [msg]);

  const handleClose = () => {
    setDisplayRazorpay(false);
    setOrderDetails(null);
  };

  const getRandomText = () => {
    const texts = ["Loading...", "Data not available", "N/A"];
    return texts[Math.floor(Math.random() * texts.length)];
  };

  return (
    <>
      <div className="form-bg">
        <div className="grid 2xl:grid-cols-12 xl:grid-cols-12 lg:grid-cols-12 md:grid-cols-12 mr-3 ">
          <div className="col-span-6 sm:col-span-6 xs:col-span-6 xs:py-16">
            <div className="full-left-form-div">
              <div>
                <div>
                  <h2 className="left-form-div-head">{titel}</h2>
                </div>
                <p className="left-form-div-para">{description}</p>
                <div className="grid grid-cols-3 gap-1">
                  <button
                    className={`${activeForm === "generateList" ? "btnWarning" : "btnSimple"
                      } text-wrap p-3 w-full text-base`}
                    onClick={(e) => {
                      setactiveform("generateList");
                      setCollegeData([]);
                      setMsg("");
                      setDescription(PreferenceList);
                      setTitle(PreferenceListTitle);
                      clearState();
                    }}
                  >
                    MHT-CET Preference List Generator
                  </button>
                  <button
                    className={`${activeForm === "CutOff" ? "btnWarning" : "btnSimple"
                      } text-wrap p-3 w-full text-base`}
                    onClick={(e) => {
                      setactiveform("CutOff");
                      setCollegeData([]);
                      setMsg("");
                      setDescription(BrachDescription);
                      setTitle(BranchTitle);
                      clearState();
                    }}
                  >
                    MHT-CET Branch-Wise College Cut-off
                  </button>
                  <button
                    className={`${activeForm === "topCollage" ? "btnWarning" : "btnSimple"
                      } text-wrap p-3 w-full text-base `}
                    onClick={(e) => {
                      setactiveform("topCollage");
                      setCollegeData([]);
                      setMsg("");
                      setDescription(BranchTopList);
                      setTitle(BranchTopListTitle);
                      clearState();
                    }}
                  >
                    MHT-CET Branch-Wise Top College
                  </button>
                </div>
              </div>
              {activeForm === "topCollage" && (
                <div className="player-wrapper">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/_bR88g5-xnA?si=DRNc9jXRAIXxEz8q"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {activeForm === "generateList" && (
                <div className="player-wrapper">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/SyWaf7TkXv8?si=aLGjjHh1Rg8GN3Mf"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    referrerPolicy="strict-origin-when-cross-origin"
                    allowFullScreen
                  ></iframe>
                </div>
              )}
              {activeForm === "CutOff" && (
                <div className="player-wrapper">
                  <iframe
                    width="100%"
                    height="350"
                    src="https://www.youtube.com/embed/yajElQ0XdOM?si=jD1Gi-nEiiSnyf7m"
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  ></iframe>
                </div>
              )}
            </div>
          </div>
          <div className="col-span-6 sm:col-span-6 xs:col-span-6 p-2 flex items-center justify-center flex-col">
            <div className="">
              <div className="student-forms">
                {activeForm === "CutOff" ? (
                  <BranchWise
                    setCollegeData={setCollegeData}
                    setTableName={setTableName}
                    universites={universites}
                    setIsData={setisData}
                    setMsg={setMsg}
                    setGender={setGender}
                    setPercentage={setPercentage}
                    setUniversiity={setUniversiity}
                    setBranch={setBranch}
                    setCategory={setCategory}
                    setForName={setForName}
                    forName={forName}
                  />
                ) : activeForm === "topCollage" ? (
                  <BranchTopCollage
                    setCollegeData={setCollegeData}
                    setTableName={setTableName}
                    universites={universites}
                    setIsData={setisData}
                    setMsg={setMsg}
                    setGender={setGender}
                    setBranch={setBranch}
                    setRelatedBranch={setRelatedBranch}
                    setForName={setForName}
                    forName={forName}
                  />
                ) : (
                  <MhtCet
                    ispremium={user?.premium}
                    setCollegeData={setCollegeData}
                    setTableName={setTableName}
                    universites={universites}
                    branchData={branchData}
                    categoryData={categoryData}
                    cities={cities}
                    setIsData={setisData}
                    setMsg={setMsg}
                    setGender={setGender}
                    setPercentage={setPercentage}
                    setUniversiity={setUniversiity}
                    setBranch={setBranch}
                    setRelatedBranch={setRelatedBranch}
                    setCategory={setCategory}
                    setAmount={setAmount}
                    setForName={setForName}
                    forName={forName}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="college-data">
          {collegeData && collegeData.length ? (
            <div id="form-response" className="college-table">
              {collegeData.length === 0 && (
                <div
                  className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                  role="alert"
                >
                  <strong className="font-bold text-gray-800">{msg}</strong>
                </div>
              )}
              {collegeData.length !== 0 && (
                <h2 className="text-lg font-semibold">{tableName}</h2>
              )}
              <table className="college-items table-auto w-full">
                {collegeData.length !== 0 && (
                  <thead>
                    <tr className="table-header text-white">
                      <th className="px-4 py-2"> # </th>
                      <th className="px-4 py-2"> Branch ID </th>
                      <th className="px-4 py-2"> College Name </th>
                      {activeForm !== "topCollage" && (
                        <th className="px-4 py-2"> Branch </th>
                      )}
                      {activeForm !== "topCollage" && (
                        <th className="px-4 py-2"> Category </th>
                      )}
                      {activeForm !== "topCollage" && (
                        <th className="px-4 py-2"> Percentile </th>
                      )}
                      {activeForm !== "topCollage" && (
                        <th className="px-4 py-2"> Closing Rank </th>
                      )}
                      {activeForm === "topCollage" && (
                        <th className="px-4 py-2"> City </th>
                      )}
                    </tr>
                  </thead>
                )}
                <tbody className="relative z-10">
                  {activeForm === "generateList" &&
                    collegeData.length > 3 &&
                    role !== "SA" && (
                      <div colSpan="6 relative">
                        <button
                          onClick={createRazorPayOrder}
                          className="btnWarning  absolute h-10 w-32 resposive-btn"
                        >
                          Get Full List
                        </button>
                        {orderDetails && displayRazorpay && (
                          <RenderRazorpay
                            amount={orderDetails.amount}
                            currency={orderDetails.currency}
                            orderId={orderDetails.order_id}
                            keyId={process.env.REACT_APP_RAZORPAY_KEY_ID}
                            keySecret={
                              process.env.REACT_APP_RAZORPAY_KEY_SECRET
                            }
                            userId={user._id}
                            onClose={handleClose}
                            triggerDownload={triggerDownload} // Correct the prop name here
                            displayRazorpay={displayRazorpay}
                            togglePopup={togglePopup}
                          />
                        )}
                      </div>
                    )}
                  {collegeData.map((college, i) => {
                    const isBlurred = activeForm === "generateList" && i >= 3;
                    return (
                      <React.Fragment key={i}>
                        {role == "SA" ? (
                          <tr className="bg-gray-100 border-b border-gray-200 college-item">
                            <td className="px-4 py-2">{i + 1}</td>
                            <td className="px-4 py-2">{college.branch_id}</td>
                            <td className="px-4 py-2">
                              {college.college_name}
                            </td>
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {college.branch_name}
                              </td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">{college.category}</td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {college.closing_percentile_2024}
                              </td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {college.closing_rank_2024}
                              </td>
                            )}
                            {activeForm === "topCollage" && (
                              <td className="px-4 py-2">
                                {college?.city?.charAt(0).toUpperCase() +
                                  college?.city?.slice(1)}
                              </td>
                            )}
                          </tr>
                        ) : (
                          <tr
                            className={`bg-gray-100 border-b border-gray-200 college-item ${isBlurred ? "blurred" : ""
                              }`}
                          >
                            <td className="px-4 py-2">{i + 1}</td>
                            <td className="px-4 py-2">
                              {isBlurred ? getRandomText() : college.branch_id}
                            </td>
                            <td className="px-4 py-2">
                              {isBlurred
                                ? getRandomText()
                                : college.college_name}
                            </td>
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {isBlurred
                                  ? getRandomText()
                                  : college.branch_name}
                              </td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {isBlurred ? getRandomText() : college.category}
                              </td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {isBlurred
                                  ? getRandomText()
                                  : college.closing_percentile_2024}
                              </td>
                            )}
                            {activeForm !== "topCollage" && (
                              <td className="px-4 py-2">
                                {isBlurred
                                  ? getRandomText()
                                  : college.closing_rank_2024}
                              </td>
                            )}
                            {activeForm === "topCollage" && (
                              <td className="px-4 py-2">
                                {isBlurred
                                  ? getRandomText()
                                  : college?.city?.charAt(0).toUpperCase() +
                                  college?.city?.slice(1)}
                              </td>
                            )}
                          </tr>
                        )}
                      </React.Fragment>
                    );
                  })}
                </tbody>
              </table>
              {collegeData.length !== 0 && (
                <div className="download-btn-parent flex gap-5 items-center">
                  <div>
                    {" "}
                    {role == "SA" && (
                      <button
                        onClick={() => {
                          downloadData(collegeData);
                        }}
                        className="download-btn"
                      >
                        Download
                      </button>
                    )}
                  </div>
                  <div>
                    <strong>Disclaimer:</strong>
                    <p>
                      The information, predictions, and content provided by
                      Campus Connect, including college predictor and
                      branch-wise prediction tools, are intended solely for
                      reference purposes. While we strive to ensure the accuracy
                      and reliability of the data presented, we do not guarantee
                      or assure that the results and outcomes shown are 100%
                      accurate or conclusive. Candidates are strongly advised to
                      independently verify and validate all information before
                      making any decisions based on the provided.
                    </p>
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div
              className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative text-center"
              role="alert"
            >
              <>{msg}</>
            </div>
          )}
        </div>
      </div>
      <NewFooter />
      <div>
        <Popup show={isPopupOpen} onClose={togglePopup} user={user} />
      </div>
    </>
  );
}


const Popup = ({ show, onClose, user }) => {
  if (!show) {
    return null;
  }

  return (
    <div className="popup-overlay">
      <div className="popup-content relative">
        <div className="bg-customeBg p-3 text-white">
          <h2>Finalize Your Preference List Order</h2>
        </div>
        <div>
          <p className="leading-8">
            Dear <strong>{user.firstName}</strong>,

            <br />
            We noticed that you added our Preference List for CAP Round to your cart but didn't complete the purchase.
            We understand that there might have been issues with the payment gateway or other concerns.
            <br />
            We highly value your interest and want to make this process as smooth as possible for you. Here are two ways you can complete your purchase:
            <br />
            <strong>Try Again:</strong> Click the link below to return to your cart and complete the purchase:
            <br />
            <a href="https://yourcartlink.com" target="_blank" rel="noopener noreferrer">
              Complete Your Purchase
            </a>
            <br />
            <strong>Google Form:</strong> If you encounter any issues, simply fill out the Google Form, and we will send you your preference list after successful payment.
            <br />
            <a href="https://forms.gle/3oxzMGBj7b9psRq1A" target="_blank" rel="noopener noreferrer" className="text-customeBlue">
              https://forms.gle/3oxzMGBj7b9psRq1A
            </a>
            <br />
            Don't miss this chance to get your personalized preference list and ace your admissions!
            <br />
            Best regards,
            <br />
            Team Campus Connect.
          </p>
        </div>
        <button onClick={onClose} className=" text-white absolute top-0 right-0"><FaCircleXmark color="red" size={25} /></button>
      </div>
    </div>
  );
};
