import NewFooter from "../NewFooter/NewFooter";

export default function Policy() {
  return (
    <div className="container-fluid privacyPolicy">
      <div className="mx-10 mt-5">
        <h1>Privacy Policy</h1>
        <p className="my-3 info">
          Welcome to Campus Connect! We are committed to protecting your privacy
          and ensuring your personal information is handled in a safe and
          responsible manner. This Privacy Policy explains how we collect, use,
          and safeguard your information when you visit our website, and use our
          services.
        </p>

        <div className="content">
          <h3>Information We Collect</h3>
          <p>
            <strong>Personal Information:</strong>We may collect personal
            information such as your name, email address, phone number, postal
            address, date of birth, and educational background when you register
            on our website, subscribe to our newsletter, or fill out a form.
          </p>
          <p>
            <strong>Payment Information:</strong>If you make a purchase or pay
            for services on our website, we collect payment information such as
            your credit card details, billing address, and other financial data
            necessary to process your payment.
          </p>
          <p>
            <strong>Technical Information:</strong> We collect information about
            your device and internet connection, including IP address, browser
            type, operating system, and browsing history, through cookies and
            similar technologies.
          </p>
          <p>
            <strong>Usage Data: </strong> We collect information about your
            device and internet connection, including IP address, browser type,
            operating system, and browsing history, through cookies and similar
            technologies.
          </p>
        </div>
        <div className="content">
          <h3>How We Use Your Information</h3>
          <p>
            <strong>We use the information we collect to:</strong>
          </p>
          <p>Provide, operate, and maintain our website and services.</p>
          <p>
            Process transactions and send you related information, including
            purchase confirmations and invoices.
          </p>
          <p>
            Communicate with you, including responding to your comments,
            questions, and requests.
          </p>
          <p>
            Send you technical notices, updates, security alerts, and support
            messages.
          </p>
          <p>
            Improve our website and services, including performing analytics and
            conducting research.
          </p>
          <p>
            Personalize your experience on our website and deliver content and
            product offerings relevant to your interests.
          </p>
          <p>
            Monitor and analyse trends, usage, and activities to improve our
            website and services.
          </p>
          <p>
            Detect, prevent, and address technical issues and security risks.
          </p>
          <p>
            Comply with legal obligations and enforce our terms and conditions.
          </p>
        </div>
        <div className="content">
          <h3>Sharing Your Information:</h3>
          <p>
            <strong>We may share your information with:</strong>
          </p>
          <p>
            <strong>Service Providers:</strong>Third-party vendors who provide
            services such as payment processing, data analysis, email delivery,
            and hosting services.
          </p>
          <p>
            <strong>Affiliates and Partners:</strong>Trusted affiliates and
            partners for marketing, advertising, and other purposes
          </p>
          <p>
            <strong>Legal Authorities:</strong> Government authorities or other
            entities if required by law or to protect our rights.
          </p>
        </div>
        <div className="content">
          <h3>Data Security:</h3>
          <p>
            We implement appropriate technical and organizational measures to
            protect your personal information from unauthorized access,
            disclosure, alteration, and destruction. While we strive to use
            commercially acceptable means to protect your information, no method
            of transmission over the Internet or method of electronic storage is
            100% secure.
          </p>
        </div>
        <div className="content">
          <h3>Your Rights:</h3>
          <p>
            <strong>You have the right to:</strong>
          </p>
          <p>Access, update, or delete your personal information.</p>
          <p>
            Object to or restrict the processing of your personal information.
          </p>
          <p>
            Request the transfer of your personal information to another service
            provider.
          </p>
          <p>
            Withdraw consent at any time where we are relying on consent to
            process your personal information.
          </p>
          <p>
            To exercise these rights, please contact us at{" "}
            <a className="url" href="mailto:campusconnectapps@gmail.com">
            campusconnectapps@gmail.com

            </a>
            {" "}
          </p>
        </div>
        <div className="content">
          <h3>Cookies and Tracking Technologies:</h3>
          <p>
            We use cookies and similar tracking technologies to track the
            activity on our website and store certain information. You can
            instruct your browser to refuse all cookies or to indicate when a
            cookie is being sent. However, if you do not accept cookies, you may
            not be able to use some portions of our website
          </p>
        </div>
        <div className="content">
          <h3>Third-Party Links:</h3>
          <p>
            Our website may contain links to third-party websites. We are not
            responsible for the privacy practices or the content of these
            websites. We encourage you to review the privacy policies of these
            third-party websites.
          </p>
        </div>
        <div className="content">
          <h3>Changes to This Privacy Policy:</h3>
          <p>
            We may update our Privacy Policy from time to time. We will notify
            you of any changes by posting the new Privacy Policy on this page
            and updating the effective date. You are advised to review this
            Privacy Policy periodically for any changes.
          </p>
        </div>
        <div className="content">
          <h3>Contact Us:</h3>
          <p>
            <strong>
              If you have any questions about this Privacy Policy, please
              contact us at:
            </strong>
          </p>
          <p>
            Campus Connect a Product by GDB Learning Solutions Private Limited
          </p>
          <p>
            S.No.22/1, Flat No.101, B-Building, B-Wing, Kamalacity, Pune, Pune
          </p>
          <p>
            <a className="url" href="mailto:campusconnectapps@gmail.com">
            campusconnectapps@gmail.com
            </a>
          </p>
          <p>
        Contact:{" "}
        <a className="url" href="tel:+91 99229 13434">
          +91 99229 13434
        </a>
      </p>
          <p>
            By using our website and services, you consent to our Privacy
            Policy.
          </p>
        </div>
      </div>
      <NewFooter />
    </div>
  );
}
