
import "./Offer.css"
const Offer = () => {
    return (
        <div className="top-container">
            <div className="containers p-10">
                <div className="boxs box1s">
                    <div>
                        <div className="line-above">
                            {/* <span class="number">1</span> */}
                            <span className="number-head">Personalized Guidance</span>
                        </div>
                        <div className="line-to-center">
                            {/* <img src={img}></img> */}
                        </div>
                        <p className="para-classes">Our platform uses advanced algorithms to offer personalized college recommendations based on your unique profile, exam scores, and preferences.</p>
                    </div>
                </div>
                <div className="boxs box2s">
                    <div className="right-smal-box">
                        <div className="line-above-right">
                            <span className="number-head-right">Expert Support</span>
                            {/* <span class="number-right">2</span> */}

                        </div>
                        <div className="line-to-center">
                            {/* <img src={img}></img> */}
                        </div>
                        <p className="para-classes">Benefit from the knowledge and experience of our team of experts who are dedicated to assisting you through every step of the admission process.</p>
                    </div>
                </div>
                <div className="boxs box3s">
                    <div>
                        <div className="line-above">
                            {/* <span class="number">1</span> */}
                            <span class="number-head">Comprehensive Resources</span>
                        </div>
                        <div className="line-to-center">
                            {/* <img src={img}></img> */}
                        </div>
                        <p className="para-classes">Access a wealth of resources, including study materials, practice tests, and financial aid information, all designed to help you succeed.</p>
                    </div>

                </div>

                <div className="boxs box4s">
                    <div>
                        <div className="line-above-right">

                            <span className="number-head-right">Interactive Community</span>
                            {/* <span class="number-right-last">4</span> */}
                        </div>
                        <div className="line-to-center">
                            {/* <img src={img}></img> */}
                        </div>
                        <p className="para-classes">Join a vibrant community of students, parents, and educators. Share experiences, ask questions, and find support from peers who are on the same journey..</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Offer;
