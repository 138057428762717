import React from 'react';
import NewFooter from '../NewFooter/NewFooter';
import './NewGallery.css'

const videoLinks = [
  'https://www.youtube.com/embed/SyWaf7TkXv8',
  'https://www.youtube.com/embed/yajElQ0XdOM',
  'https://www.youtube.com/embed/_bR88g5-xnA',
  'https://www.youtube.com/embed/wCbxX04-Vc0'
];

const Gallery = () => {
  return (
    <>
    <div className="container mx-auto pt-8 pb-20 px-20 xs:px-0 sm:p-0  md:px-4">
      {/* <h2 className="text-2xl text-semibold mb-2">Gallery</h2> */}
      <div className="grid grid-cols-3 sm:grid-cols-1 xs:grid-cols-1 gap-4">
        {videoLinks.map((link, index) => (
          <div key={index} className="player-wrapper shadow-md">
            <iframe
              width="100%"
              height="250"
              src={link}
              title={`Video ${index + 1}`}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        ))}
      </div>
    </div>
    <NewFooter/>
    </>
  );
};

export default Gallery;
