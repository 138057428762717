import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import NewFooter from "../NewFooter/NewFooter";
import toast from 'react-hot-toast';
import axios from 'axios';

const TalkWithExpert = () => {
    const baseUrl=process.env.REACT_APP_BASE_URL;
    const { register, handleSubmit,reset, formState: { errors } } = useForm();

    const category = ['OPEN', "OBC", "SC", "ST", "NT", "EWS", "other"];
    const branches = ["Computer Science", "Information Technology", "Electronics and Communication", "Mechanical Engineering", "Civil Engineering", "Electrical Engineering", "Chemical Engineering", "Other"];
    const cities = ["Pune", "Ahmednagar", "Nagpur", "Raigad"];
    const helpAreas = ["Document Preparation", "College Preference List Generation", "Branch Selection Guidance", "Understanding Percentile vs. Rank", "On-Spot Admission Assistance", "Scholarship and Financial Aid Guidance"];
    const preferenceList = ["Rs,999/-   for Round-1", "Rs,3000/-   for  3 Rounds", "Rs,5000/-   for 3 Rounds  including spot round", "Not Intrested"]

    const onSubmit = async (data) => {
        console.log(data);
        try {
                const response = await axios.post(`${baseUrl}/public/talk-with-expert`, data, {
                headers: {
                    "Content-Type": "application/json",
                }
            });

            toast.success(response.data.message);
            reset();
        } catch (error) {
            console.error('Error submitting the form:', error);
            toast.error("An error occurred while submitting the form. Please try again later")
        }
    };

    return (
        <div>
            <div className="items-center p-8">
                <div className="flex flex-col items-center">
                    <h1 className="text-2xl text-[#446282] font-semibold mb-4">Submit Your Application</h1>
                    <form className="application-form form-group" onSubmit={handleSubmit(onSubmit)}>
                        <div className='grid md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 2xl:grid-cols-2 gap-4'>
                            <div>
                                <label>
                                    Full Name *
                                    <input
                                        {...register("fullName", { required: "Full Name is required" })}
                                        type="text"
                                        placeholder="Your Full Name"
                                        className="input-field"
                                    />
                                    {errors.fullName && <span className="error-class">{errors.fullName.message}</span>}
                                </label>
                            </div>
                            <div>
                                <label>
                                    Phone Number *
                                    <input
                                        {...register("phoneNumber", { required: "Phone Number is required", pattern: { value: /^\d{10}$/, message: "Phone number must be exactly 10 digits" } })}
                                        type="text"
                                        placeholder="Your Phone Number"
                                        className="input-field"
                                    />
                                    {errors.phoneNumber && <span className="error-class">{errors.phoneNumber.message}</span>}
                                </label>
                            </div>
                            <div>
                                <label>
                                    Email Address *
                                    <input
                                        {...register("emailAddress", { required: "Email Address is required", pattern: { value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email address" } })}
                                        type="email"
                                        placeholder="Your Email Address"
                                        className="input-field"
                                    />
                                    {errors.emailAddress && <span className="error-class">{errors.emailAddress.message}</span>}
                                </label>
                            </div>
                            <div>
                                <label>
                                    City *
                                    <input
                                        {...register("location", { required: "City is required" })}
                                        type="text"
                                        placeholder="Your City"
                                        className="input-field"
                                    />
                                    {errors.location && <span className='error-class'>{errors.location.message}</span>}
                                </label>
                            </div>
                            <div>
                                <label>
                                    MHT-CET Percentile *
                                    <input
                                        {...register("mhtCetPercentile", { required: "MHT-CET Percentile is required" })}
                                        type="text"
                                        placeholder="Your MHT-CET Percentile"
                                        className="input-field"
                                    />
                                    {errors.mhtCetPercentile && <span className="error-class">{errors.mhtCetPercentile.message}</span>}
                                </label>
                            </div>
                            <div>
                                <label>
                                    JEE Main Score (If Applicable)
                                    <input
                                        {...register("jeeMainScore")}
                                        type="text"
                                        placeholder="Your JEE Main Score"
                                        className="input-field"
                                    />
                                </label>
                            </div>
                            <div >
                                <label>
                                    Preferred City or University *
                                    <input
                                        type="text"
                                        {...register("cityOrUniversity", { required: "Select at least one city or university" })}
                                        className="input-field"
                                        placeholder='City or University'
                                    />
                                    {errors.cityOrUniversity && <span className="error-class">{errors.cityOrUniversity.message}</span>}
                                </label>
                            </div>
                            <div >
                                <label>
                                    Category *
                                    <select
                                        {...register("category", { required: "Category is required" })}
                                        className="input-field w-full"
                                    >
                                        <option value="">Select Category</option>
                                        {category.map((item, index) => (
                                            <option key={index} value={item}>{item}</option>
                                        ))}
                                    </select>
                                    {errors.category && <span className="error-class">{errors.category.message}</span>}
                                </label>
                            </div>
                        </div>
                        <div className='w-full' >
                            <label>
                                Preference List _ Charges *
                                <select
                                    {...register("preferenceListCharges", { required: "prefrence list is required" })}
                                    className="input-field w-full"
                                >
                                    <option value="">Select Category</option>
                                    {preferenceList.map((item, index) => (
                                        <option key={index} value={item}>{item}</option>
                                    ))}
                                </select>
                                {errors.preferenceListCharges && <span className="error-class">{errors.preferenceListCharges.message}</span>}
                            </label>
                        </div>
                        <div className='w-full'>
                            <label className="block mb-4">
                                Interest of counseling in person *
                                <div className="flex items-center gap-4 pt-2 px-2">
                                    <div className='flex items-center'>
                                        <input
                                            {...register("interestOfCounseling", { required: "This field is required" })}
                                            type="radio"
                                            value="Yes"
                                            className="m-0 p-0"
                                        />
                                        <span className="ml-2">Yes</span>
                                    </div>
                                    <div className='flex items-center'>
                                        <input
                                            {...register("interestOfCounseling", { required: "This field is required" })}
                                            type="radio"
                                            value="No"
                                            className="m-0 p-0"
                                        />
                                        <span className="ml-2">No</span>
                                    </div>
                                </div>
                                {errors.interestOfCounseling && <span className="error-class">{errors.interestOfCounseling.message}</span>}
                            </label>
                        </div>


                        <div className="form-group">
                            <label>
                                Preferred Engineering Branches (Select up to 3) *
                                <div className="checkbox-group">
                                    {branches.map((branch, index) => (
                                        <div key={index} className="flex items-center px-4 gap-2 py-2">
                                            <input
                                                type="checkbox"
                                                value={branch}
                                                {...register("branches", { required: "Select at least one branch" })}
                                                className="w-4 h-4 mr-2"
                                            />
                                            <span className='text-pure-greys-500'>{branch}</span>
                                        </div>
                                    ))}
                                </div>
                                {errors.branches && <span className="error-class">{errors.branches.message}</span>}
                            </label>
                        </div>

                        <div className="form-group">
                            <label>
                                What specific areas do you need help with? (Select all that apply) *
                                <div className="checkbox-group">
                                    {helpAreas.map((helpArea, index) => (
                                        <div key={index} className="flex items-center px-4 gap-2 py-2">
                                            <input
                                                type="checkbox"
                                                value={helpArea}
                                                {...register("areaOfHelp", { required: "Select at least one area" })}
                                                className="w-4 h-4 mr-2"
                                            />
                                            <span className='text-pure-greys-500'>{helpArea}</span>
                                        </div>
                                    ))}
                                </div>
                                {errors.areaOfHelp && <span className="error-class">{errors.areaOfHelp.message}</span>}
                            </label>
                        </div>
                        <div className='form-group'>
                            <label>
                                Any additional comments or questions?
                                <textarea
                                    {...register("comments")}
                                    placeholder="Your comments or questions"
                                    className="textarea-field"
                                />
                            </label>
                        </div>
                        <div>
                            <button type="submit" className="btn-submit">Submit Your Application</button>
                        </div>
                    </form>
                </div>
            </div>
            <NewFooter />
        </div>
    );
};

export default TalkWithExpert;
