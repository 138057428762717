import { useEffect } from "react";
import { FaUser, FaWhatsapp, FaChevronDown } from "react-icons/fa6";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
// import { useLogout } from "../hooks/useLogout";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import logopng from "../../components/Assets/img/CampusConnectWhitelogo.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../services/operations/authAPI";
import { useState, Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function NewNavbar() {
  const { token, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [navigation, setNavigation] = useState([
    { name: "Home", href: "", current: true },
    {
      name: "About",
      current: false,
      href: "about",
      subItems: [
        { name: "What We Offer", href: "what-we-offer" },
        { name: "Gallery", href: "gallery" },
      ],
    },
    { name: "Talk with expert", href: "talk-with-expert", current: false },
    { name: "Work with us", href: "work-with-us", current: false },
    { name: "Contact us", href: "contact-us", current: false },
  ]);

  const handleLogout = () => {
    dispatch(logout(navigate));
    navigate("/");
  };

  const handleClick = (href) => {
    setNavigation(
      navigation.map((item) => ({
        ...item,
        current: item.href === href,
      }))
    );
    navigate(`/${href}`);
  };

  // const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setScreenWidth(window.innerWidth);
  //     if(screenWidth){
  //       window.location.reload();
  //     }
  //   };
  //   window.addEventListener('resize', handleResize);
  //   return () => {
  //     window.removeEventListener('resize', handleResize);
  //   };
  // }, []);

  return (
    <Disclosure as="nav" className="bg-gray-800 box">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-8xl px-4 sm:px-6 lg:px-8">
            <div className="relative flex h-24 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center sm:hidden lg:hidden xl:hidden 2xl:hidden">
                {/* Mobile menu button*/}
                <DisclosureButton className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className="flex flex-1 items-center justify-center  lg:justify-start xl:justify-start 2xl:justify-start">
                <div className="flex flex-shrink-0 items-center">
                  <img
                    className="h-16 w-auto"
                    src={logopng}
                    alt="Your Company"
                  />
                </div>
                <div className="hidden sm:ml-6 lg:block xl:block 2xl:block content-center">
                  <div className="flex  justify-center py-2 items-center space-x-4 lg:space-x-2 relative">
                    {navigation.map((item) =>
                      item.subItems ? (
                        <div key={item.name} className="relative flex">
                          <span
                            onClick={() => handleClick(item.href)}
                            className={classNames(
                              item.current
                                ? "bg-gray-900 textBlue"
                                : "text-gray-300 hover:bg-gray-700 hover:textBlue",
                              "rounded-md px-3 py-2 cursor-pointer font-medium flex items-center"
                            )}
                          >
                            {item.name}
                          </span>
                          <Menu as="div" className="relative flex z-50">
                            <MenuButton className="absolute flex left-[-1rem] top-3 justify-start items-center rounded-md font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none">
                              <FaChevronDown
                                className="h-4 w-7"
                                aria-hidden="true"
                              />
                            </MenuButton>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <MenuItems
                                className="absolute right-0 mt-2 w-56 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none [--anchor-gap:10px]"
                                anchor="bottom right"
                              >
                                {item.subItems.map((subItem) => (
                                  <MenuItem key={subItem.name}>
                                    {({ active }) => (
                                      <Link
                                        to={`/${subItem.href}`}
                                        className={classNames(
                                          active ? "bg-gray-100" : "",
                                          "block px-4 py-2 text-sm text-gray-700"
                                        )}
                                      >
                                        {subItem.name}
                                      </Link>
                                    )}
                                  </MenuItem>
                                ))}
                              </MenuItems>
                            </Transition>
                          </Menu>
                        </div>
                      ) : (
                        <span
                          key={item.name}
                          onClick={() => handleClick(item.href)}
                          className={classNames(
                            item.current
                              ? "bg-gray-900 textBlue"
                              : "text-gray-300 hover:bg-gray-700 hover:textBlue",
                            "rounded-md px-3 py-2 cursor-pointer font-medium"
                          )}
                          aria-current={item.current ? "page" : undefined}
                        >
                          {item.name}
                        </span>
                      )
                    )}
                    <Menu
                      as="span"
                      className="absolute lg:right-24 xl:right-28  2xl:right-28 inline-block text-left"
                    >
                      <MenuButton className="flex  justify-center py-2 items-center rounded-md  font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none">
                        <FaChevronDown
                          className="h-4 w-5 "
                          aria-hidden="true"
                        />
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="absolute right-0 mt-2 w-56 origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                          <MenuItem>
                            {({ active }) => (
                              <Link
                                to="terms-and-conditons"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Terms & Conditions
                              </Link>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <Link
                                to="/privacy-policy"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Privacy Policy
                              </Link>
                            )}
                          </MenuItem>
                          <MenuItem>
                            {({ active }) => (
                              <Link
                                to="/refund-policy"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Refund Policy
                              </Link>
                            )}
                          </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                    <span></span>{" "}
                    <Link
                      to="https://chat.whatsapp.com/E1qUggR7qAv7lx7Od3vgnM"
                      className="rounded-md py-2 px-3 cursor-pointer font-medium flex gap-2 items-center"
                    >
                      {" "}
                      Join <FaWhatsapp size="20px" />
                    </Link>
                  </div>
                </div>
              </div>
              {token && (
                <div className="absolute inset-y-0 right-0 flex items-center sm:static sm:inset-auto sm:ml-6 sm:pr-0">
                  <button
                    type="button"
                    className="relative rounded-full bg-gray-800 p-1 text-gray-400 hover:textBlue focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                  >
                    <span className="absolute -inset-1.5" />
                    <span className="sr-only">View notifications</span>
                    <BellIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                    <div>
                      <MenuButton
                        className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                        style={{ outline: "none" }}
                      >
                        {/* <img
                          className="h-8 w-8 rounded-full"
                          src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        /> */}
                        {/* <span className="sr-only">Open user menu</span> */}
                        <div className="user-icon">
                          <img
                            src={user.image}
                            width={25}
                            alt="user-image"
                            style={{ borderRadius: '50%', objectFit: 'cover' }}
                          />
                        </div>
                      </MenuButton>
                    </div>
                    <Transition
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      
                      <MenuItems className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <MenuItem>
                          {({ focus }) => (
                            <span
                              className={classNames(
                                focus ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 cursor-pointer"
                              )}
                              onClick={() => {
                                handleLogout();
                              }}
                            >
                              Sign out
                            </span>
                          )}
                        </MenuItem>
                      </MenuItems>
                    </Transition>
                  </Menu>
                </div>
              )}
            </div>
          </div>

          {/* <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) =>
                item.subItems ? (
                  <div key={item.name} className="relative">
                    <span
                      onClick={() => handleClick(item.href)}
                      className={classNames(
                        item.current
                          ? "bg-gray-900 textBlue"
                          : "text-gray-300 hover:bg-gray-700 hover:textBlue",
                        "rounded-md px-3 py-2 cursor-pointer font-medium flex items-center block w-full"
                      )}
                    >
                      {item.name}
                    </span>
                    <Menu as="div" className="w-full flex">
                      <MenuButton className="absolute  flex justify-center items-center bottom-5 left-20  inline-flex justify-start rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none">
                        <FaChevronDown className="absolute h-4 w-4"/>
                        
                      </MenuButton>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <MenuItems className="mt-2 space-y-1 pl-2 py-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                        <MenuItem>
                      {({ active }) => (
                        <Link
                          to="what-we-offer"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          What we offer
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="gallery"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                         Gallery
                        </Link>
                      )}
                    </MenuItem>
                        </MenuItems>
                      </Transition>
                    </Menu>
                  </div>
                ) : (
                  <span
                    key={item.name}
                    onClick={() => handleClick(item.href)}
                    className={classNames(
                      item.current
                        ? "bg-gray-900 textBlue"
                        : "text-gray-300 hover:bg-gray-700 hover:textBlue",
                      "rounded-md px-3 py-2 cursor-pointer font-medium block w-full"
                    )}
                    aria-current={item.current ? "page" : undefined}
                  >
                    {item.name}
                  </span>
                )
              )}

              <Menu as="div" className="relative inline-block text-left w-full">
                <MenuButton
                  className="absolute bottom-5 left-20  inline-flex justify-start rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none"
                  style={{ outline: "none" }}
                >
                  <FaChevronDown
                    className="ml-2 h-4 w-4 outline-none"
                    aria-hidden="true"
                  />
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 mt-2 w-full origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="terms-and-conditons"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Terms & Conditions
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="/privacy-policy"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Privacy Policy
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="/refund-policy"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Refund Policy
                        </Link>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </DisclosurePanel>  */}

          <DisclosurePanel className="sm:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  onClick={() => handleClick(item.href)}
                  className={classNames(
                    item.current
                      ? "bg-gray-900 textBlue"
                      : "text-gray-300 hover:bg-gray-700 hover:textBlue",
                    "block rounded-md px-3 py-2.5 cursor-pointer font-medium"
                  )}
                  aria-current={item.current ? "page" : undefined}
                >
                  {item.name}
                </DisclosureButton>
              ))}

              <Menu as="div" className="relative w-full flex">
                <MenuButton className="absolute  flex justify-center items-center bottom-44 left-20 rounded-md text-sm font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none">
                  <FaChevronDown className="absolute h-4 w-4 mt-3" />
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className=" absolute bottom-10 mt-2 space-y-1 pl-2 py-2 w-full rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="what-we-offer"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          What we offer
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="gallery"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Gallery
                        </Link>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>

              <Menu as="div" className="relative inline-block text-left w-full">
                <MenuButton
                  className="absolute bottom-6 left-20  inline-flex justify-start rounded-md px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:textBlue focus:outline-none"
                  style={{ outline: "none" }}
                >
                  <FaChevronDown
                    className="ml-2 h-4 w-4 outline-none"
                    aria-hidden="true"
                  />
                </MenuButton>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <MenuItems className="absolute right-0 mt-2 w-full origin-top-right rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none z-50">
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="terms-and-conditons"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Terms & Conditions
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="/privacy-policy"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Privacy Policy
                        </Link>
                      )}
                    </MenuItem>
                    <MenuItem>
                      {({ active }) => (
                        <Link
                          to="/refund-policy"
                          className={classNames(
                            active ? "bg-gray-100" : "",
                            "block px-4 py-2 text-sm text-gray-700"
                          )}
                        >
                          Refund Policy
                        </Link>
                      )}
                    </MenuItem>
                  </MenuItems>
                </Transition>
              </Menu>
            </div>
          </DisclosurePanel>
        </>
      )}
    </Disclosure>
  );
}
