import { Link } from "react-router-dom"
import "./NewFooter.css"
import insta from "../../components/Assets/img/instagram.png"
import facebook from "../../components/Assets/img/facebook0.png"
export default function NewFooter() {
  return (
    <div className="footer">
      <div className="footer11">
        {/* <p> &copy;Campus Connect </p> */}
        <p>Concept by GDB Learning Solutions Pvt Ltd, Developed by Innovative Technexus LLP.</p>
        <ul className="mt-3">
          <Link to="https://www.instagram.com/campusconnecthub/"><li><img src={insta} alt="instagram icon" /></li></Link>
          <Link to="https://www.facebook.com/campusconnecthub"><li><img src={facebook} alt="facebook icon" /></li></Link>
        </ul>
      </div>
    </div>
  )
}
